import React, { Component } from "react";
import DynamicTableWithSelectAllComponentNew from "../Components/DynamicTableWithSelectAllNew";
import constants from "../Utils/constants";
import { ClientServices } from "../Services/Client";
import queryString from "query-string";
import { formatRoute } from "react-router-named-routes";
import ComapnyDetailsDateTimeFormatter from "../Utils/ComapnyDetailsDateTimeFormatter";
import Search from "../Components/Search";
import { connect } from "react-redux";
import AclAction from "../Components/ACL/AclAction";
import DailogNew from "../Components/DailogNew";
import { activeMenuTab } from "../Store/Actions/User";
import { clients } from "../Store/Actions/Client";
import CustomDropdownList from "../Components/CustomDropdownList";
import CustomReactTooltip from "../Components/CustomReactTooltip";
import Slugify from "../Utils/Slugify";
import { NavLink as RRNavLink } from "react-router-dom";
import { NavLink } from "reactstrap";
import permissionCheck from "../lib/permissionCheck";
import DocumentTitle from "../Utils/DocumentTitle";
import IsTBRIClient from "../Utils/IsTBRIClient";
import Toast from "../Utils/Toast";
import CustomEllipsis from "../Utils/CustomEllipsis";
import { ProjectServices } from "../Services/Project";
import isRole_Acc_mgr_or_salesAdmin from "../Utils/isRole_Acc_mgr_or_salesAdmin";

const tableHeader = [
  {
    id: "name",
    title: "Name",
    sortable: true,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: {
      color: "#99a0aa",
      textTransform: "capitalize",
      textAlign: "left",
    },
    rowClass: "position-inherit-important",
    visibility: true,
  },
  {
    id: "segments",
    title: "Segment",
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    visibility: true,
  },
  {
    id: "additional_segments",
    title: "Additional Segments",
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    visibility: true,
  },
  {
    id: "client",
    title: "Client",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    visibility: true,
  },
  {
    id: "project_manager",
    title: "Project Manager",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    visibility: true,
  },
  {
    id: "updated_at",
    title: "Last Modified At",
    sortable: true,
    headerStyle: { textAlign: "left" },
    rowStyle: { textAlign: "left" },
    visibility: true,
  },
  {
    id: "created_by",
    title: "Created By",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    visibility: true,
  },
  {
    id: "created_at",
    title: "Created On",
    sortable: true,
    headerStyle: { textAlign: "left" },
    rowStyle: { textAlign: "left" },
    visibility: true,
  },
  {
    id: "is_active",
    title: "Status",
    sortable: true,
    headerStyle: {},
    headerClass: "text-center",
    rowStyle: { textAlign: "center" },
    visibility: true,
  },
  {
    id: "actions",
    title: "Actions",
    sortable: false,
    headerStyle: {},
    headerClass: "text-center",
    rowStyle: { textAlign: "center" },
    visibility: true,
  },
];
const showActions = false;
class ListClientProjects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowData: [],
      sortColumn: constants.PAGINATION.DEFAULT_SORT_COLUMN,
      sortOrder: constants.PAGINATION.DEFAULT_SORT_ORDER,
      pageNo: constants.PAGINATION.DEFAULT_PAGE_NO,
      pageSize: constants.PAGINATION.DEFAULT_PAGE_SIZE,
      name: "",
      dailogModal: false,
      dailogModalContent: "",
      dailogModalHeader: "",
      dailogModalConfig: {},
      dailogModalStyleType: "",
      clientId: null,
      currentPageDataCount: 0,
      disableStatus: false,
    };
  }
  componentDidMount() {
    let params = queryString.parse(this.props.location.search);
    DocumentTitle(constants.APPLICATION_ROUTE.CLIENT_PROJECT.LIST.HEADER_TITLE);
    let clientId = this.props.match.params.clientId
      ? this.props.match.params.clientId
      : null;
    this.setState(
      {
        pageNo: params.page
          ? parseInt(params.page)
          : constants.PAGINATION.DEFAULT_PAGE_NO,
        pageSize: params.page_size
          ? parseInt(params.page_size)
          : constants.PAGINATION.DEFAULT_PAGE_SIZE,
        sortColumn: params.sort_column
          ? params.sort_column
          : constants.PAGINATION.DEFAULT_SORT_COLUMN,
        sortOrder: params.sort_order
          ? params.sort_order
          : constants.PAGINATION.DEFAULT_SORT_ORDER,
        name: params.search_value ? params.search_value : "",
        clientId: clientId,
      },
      () => {
        this.getTableData();
      }
    );
    this.props.activeMenuTab(
      constants.APPLICATION_ROUTE.CLIENT_PROJECT.LIST.ROUTE
    );
    ClientServices.getAllClients()
      .then((res) => {
        let clients = [];
        clients.push({ id: "all", name: "All Clients" });
        let response = res && res.data && res.data.data;
        response = response.sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase()
            ? 1
            : b.name.toLowerCase() > a.name.toLowerCase()
            ? -1
            : 0
        );
        response = response.filter((f) => !IsTBRIClient(f));
        let totalClients = clients.concat(response);
        totalClients.forEach((r) => {
          r.is_selected = String(r.id) === clientId ? true : false;
        });
        let clientid = totalClients.find((c) => c.is_selected === true)
          ? totalClients.find((c) => c.is_selected === true).id
          : "all";
        this.setState({
          clientId: clientid,
        });
        this.props.setClients(totalClients);
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
    let index = tableHeader.findIndex((element) => element.id == "actions");
    if (
      isRole_Acc_mgr_or_salesAdmin(this.props.home.loginUserSuccess.role_id)
    ) {
      index > -1 && (tableHeader[index].visibility = false);
      this.setState({ disableStatus: true });
    } else {
      index > -1 && (tableHeader[index].visibility = true);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.match.params.clientId !== prevProps.match.params.clientId) {
      let clients = [...this.props.client.clients];
      clients.forEach((c) => {
        c.is_selected = c.id == this.props.match.params.clientId ? true : false;
      });
      this.props.setClients(clients);
      this.setState(
        {
          clientId: this.props.match.params.clientId,
          pageNo: 1,
        },
        this.getTableData
      );
    }
  }

  pagePrevious = () => {
    this.setState(
      (prevState) => ({
        pageNo: prevState.pageNo - 1,
      }),
      () => this.getTableData()
    );
  };
  pageNext = () => {
    this.setState(
      (prevState) => ({
        pageNo: prevState.pageNo + 1,
      }),
      () => this.getTableData()
    );
  };
  handlePage = (input) => {
    this.setState({ pageNo: input }, () => this.getTableData());
  };
  handleSort = (clickedColumn, key) => {
    this.setState(
      {
        sortColumn: clickedColumn,
        segmentSort: false,
        sortOrder: this.state.sortOrder === "desc" ? "asc" : "desc",
      },
      () => this.getTableData()
    );
  };
  handleSearch = () => {
    this.setState({ pageNo: 1 }, () => {
      this.getTableData();
    });
  };
  toggleClientProjectConfirmationHandler = (data) => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">
          {data.is_active === 1 ? "De-Activation" : "Activation"}
        </h3>
      </>
    );
    let dailogModalContent = (
      <>
        {data.is_active === 1 ? (
          <div className="remove-body">
            <h2 className="font-weight-semibold mb-0">
              {constants.CLIENT_PROJECT.INACTIVATE_PROJECT_CONFIRM_MESSAGE}
            </h2>
          </div>
        ) : (
          <div className="activate-body">
            <h2 className="font-weight-semibold mb-0">
              {constants.CLIENT_PROJECT.ACTIVATE_PROJECT_CONFIRM_MESSAGE}
            </h2>
          </div>
        )}
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: {
        type: data.is_active === 1 ? "de-activate_project" : "activate_project",
        id: data.id,
      },
      dailogModalStyleType:
        data.is_active === 1 ? "danger-modal" : "success-modal",
    });
  };
  removeClientProjectConfirmationHandler = (data) => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">Remove Project</h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="remove-body">
          <h2 className="font-weight-semibold mb-0">
            {constants.CLIENT_PROJECT.REMOVE_PROJECT_CONFIRM_MESSAGE}
          </h2>
        </div>
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: { type: "remove_project", id: data.id },
      dailogModalStyleType: "danger-modal",
    });
  };
  removeClientProject = (projectId) => {
    ProjectServices.removeProject(projectId)
      .then((res) => {
        Toast(constants.CLIENT_PROJECT.REMOVE.SUCCESS, "success");
        this.setState({
          pageNo:
            this.state.currentPageDataCount === 1 && this.state.pageNo !== 1
              ? this.state.pageNo - 1
              : this.state.pageNo,
        });
        this.getTableData();
      })
      .catch((error) => {
        this.getTableData();
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  toggleClientProject = (projectId, isActive) => {
    ProjectServices.toggleProject(projectId, { status: isActive })
      .then((res) => {
        Toast(
          isActive
            ? constants.CLIENT_PROJECT.ACTIVATE.SUCCESS
            : constants.CLIENT_PROJECT.IN_ACTIVATE.SUCCESS,
          "success"
        );
        this.getTableData();
      })
      .catch((error) => {
        this.getTableData();
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  copyClientProjectConfirmationHandler = (data) => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">Copy</h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="activate-body">
          <h2 className="font-weight-semibold mb-0">
            {constants.CLIENT_PROJECT.COPY_PROJECT_CONFIRM_MESSAGE}
          </h2>
        </div>
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: { type: "copy_project", id: data.id },
      dailogModalStyleType: "success-modal",
    });
  };
  copyClientProject = (projectId) => {
    ProjectServices.copyProject(projectId)
      .then((res) => {
        Toast(constants.CLIENT_PROJECT.COPY.SUCCESS, "success");
        this.getTableData();
      })
      .catch((error) => {
        this.getTableData();
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  dailogModalAccept = (config) => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
    });
    switch (config.type) {
      case "remove_project":
        this.removeClientProject(config.id);
        break;
      case "de-activate_project":
        this.toggleClientProject(config.id, 0);
        break;
      case "activate_project":
        this.toggleClientProject(config.id, 1);
        break;
      case "copy_project":
        this.copyClientProject(config.id);
        break;
      default:
        break;
    }
  };
  dailogModalDecline = () => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
    });
  };
  getActions = (data) => {
    return (
      <>
        <AclAction
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.PROJECT_RESOURCE.TITLE}
          action={constants.ACL.PROJECT_RESOURCE.ACTIONS.EDIT}
        >
          <button
            tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
            data-for={`tooltip-${Slugify(
              constants.APPLICATION_ROUTE.CLIENT_PROJECT.LIST
                .EDIT_PROJECT_TOOLTIP
            )}-${data.id}`}
            onClick={(e) => {
              this.rowAction(
                constants.APPLICATION_ROUTE.CLIENT_PROJECT.LIST.EDIT_PROJECT,
                data,
                e
              );
            }}
            className={
              "dynamicTableActionButton btn btn-md d-inline-flex align-items-center"
            }
            data-tip={
              constants.APPLICATION_ROUTE.CLIENT_PROJECT.LIST
                .EDIT_PROJECT_TOOLTIP
            }
            data-iscapture="true"
          >
            <span className="material-icons-outlined text-blue-clr icon-fs-20">
              edit
            </span>
          </button>
        </AclAction>
        <AclAction
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.PROJECT_RESOURCE.TITLE}
          action={constants.ACL.PROJECT_RESOURCE.ACTIONS.COPY}
        >
          <button
            tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
            data-for={`tooltip-${Slugify(
              constants.APPLICATION_ROUTE.CLIENT_PROJECT.LIST
                .COPY_PROJECT_TOOLTIP
            )}-${data.id}`}
            onClick={(e) => {
              this.rowAction(
                constants.APPLICATION_ROUTE.CLIENT_PROJECT.LIST.COPY_PROJECT,
                data,
                e
              );
            }}
            className={
              "dynamicTableActionButton btn btn-md d-inline-flex align-items-center"
            }
            data-tip={
              constants.APPLICATION_ROUTE.CLIENT_PROJECT.LIST
                .COPY_PROJECT_TOOLTIP
            }
            data-iscapture="true"
          >
            <span className="material-icons-outlined text-blue-clr icon-fs-17">
              content_copy
            </span>
          </button>
        </AclAction>
      </>
    );
  };
  getTableData = () => {
    let queryParams = "";
    if (this.state.sortOrder) {
      queryParams =
        "&sort_order=" +
        this.state.sortOrder +
        "&sort_column=" +
        this.state.sortColumn;
    }
    if (this.state.name !== "") {
      queryParams += "&search_column=name&search_value=" + this.state.name;
    }
    if (this.state.clientId !== "all") {
      queryParams += "&client_id=" + this.state.clientId;
    }
    this.changeRoute();
    let url = "/projects";
    ProjectServices.getTotalProjects(
      `${url}?page=` +
        this.state.pageNo +
        "&page_size=" +
        this.state.pageSize +
        queryParams
    )
      .then((res) => {
        let clientData = res.data.data;
        this.setState({
          currentPageDataCount: clientData.length,
        });

        let count =
          (res.data && res.data.meta && res.data.meta.total_count) || 0;
        let checkContentPageAccess = permissionCheck(
          constants.ACL.PERMISSION_KEY,
          constants.ACL.PROJECT_RESOURCE.TITLE,
          constants.ACL.PROJECT_RESOURCE.ACTIONS.MANAGE
        );
        clientData = clientData.map((data) => {
          let projectName = data.name;
          const additionalSegments = CustomEllipsis(
            this.props?.home?.segments
              ?.filter((segment) =>
                data?.supported_segments?.includes(segment.id)
              )
              ?.map((c) => c.name)
              .join(", ")
          ) ?? { text: "-", ellipsis: false, fullText: "" };

          return {
            id: data.id,
            name: checkContentPageAccess ? (
              <NavLink
                tag={RRNavLink}
                style={{
                  padding: 0,
                  color: "#1085c6",
                  cursor: "pointer",
                  textWrap: "wrap",
                }}
                exact
                to={formatRoute(
                  constants.APPLICATION_ROUTE.CLIENT_PROJECT.MANAGE.ROUTE,
                  { clientId: data.client.id, id: data.id }
                )}
              >
                <label className={`mb-0`}>{projectName}</label>
              </NavLink>
            ) : (
              <span key={data.id} className={"datagrid_tooltip"}>
                {projectName.text}
                {/* {projectName.ellipsis && (
                  <span
                    className="material-icons-outlined icon"
                    data-for={"Tooltip-" + data.id}
                    data-tip={projectName.fullText
                      .split(",")
                      .slice(0, 10)
                      .join(",")
                      .replace(/,/g, ",<br>")}
                    data-iscapture="true"
                  >
                    info
                    <CustomReactTooltip
                      id={"Tooltip-" + data.id}
                      multiline={true}
                    />
                  </span>
                )} */}
              </span>
            ),
            segments: `${
              data.segment && data.segment.id ? data.segment.name : "-"
            }`,
            additional_segments: (
              <span className="datagrid_tooltip">
                {additionalSegments.text || "-"}
                {additionalSegments.ellipsis && (
                  <span
                    className="material-icons-outlined icon"
                    data-for={`tooltip-segments-${data.id}`}
                    data-tip={additionalSegments.fullText.replace(
                      /,/g,
                      ",<br>"
                    )}
                    data-iscapture="true"
                  >
                    info
                    <CustomReactTooltip
                      id={`tooltip-segments-${data.id}`}
                      multiline={true}
                    />
                  </span>
                )}
              </span>
            ),
            project_manager: `${
              data.project_manager.first_name
                ? data.project_manager.first_name
                : ""
            } ${
              data.project_manager.last_name
                ? data.project_manager.last_name
                : ""
            }`,
            client: data.client.name,
            created_by: `${
              data.created_by_user?.first_name
                ? data.created_by_user.first_name
                : ""
            } ${
              data.created_by_user?.last_name
                ? data.created_by_user.last_name
                : ""
            }`,
            created_at: data.created_at
              ? ComapnyDetailsDateTimeFormatter(data.created_at, false)
              : ComapnyDetailsDateTimeFormatter(new Date(), false),
            updated_at: data.updated_at
              ? ComapnyDetailsDateTimeFormatter(data.updated_at, false)
              : "-",
            is_active:
              data.is_active === 0 || data.is_active === 1 ? (
                <AclAction
                  type={constants.ACL.PERMISSION_KEY}
                  entity={constants.ACL.PROJECT_RESOURCE.TITLE}
                  action={constants.ACL.PROJECT_RESOURCE.ACTIONS.STATUS}
                >
                  <div className="custom-control custom-switch success-toggle">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id={`customSwitch${data.id}`}
                      checked={data.is_active === 1}
                      onChange={(e) => {
                        e.stopPropagation();
                        this.rowAction(
                          constants.APPLICATION_ROUTE.CLIENT_PROJECT.LIST
                            .STATUS,
                          data,
                          e
                        );
                      }}
                      disabled={this.state.disableStatus}
                    />
                    <label
                      className="custom-control-label"
                      for={`customSwitch${data.id}`}
                    ></label>
                  </div>
                </AclAction>
              ) : null,
            actions: this.getActions(data),
          };
        });
        this.setState({ rowData: clientData, count: count });
      })
      .catch((error) => {
        this.setState({ rowData: [], count: 0 });
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  handlePageSize = (e) => {
    this.setState({ pageSize: e.target.value, pageNo: 1 }, () => {
      this.getTableData();
    });
  };
  handleInput = (e) => {
    this.setState({ name: e.target.value });
  };
  rowAction = (action, data, e) => {
    e.stopPropagation();
    switch (action) {
      case constants.APPLICATION_ROUTE.CLIENT_PROJECT.LIST.EDIT_PROJECT:
        this.props.history.push({
          pathname: formatRoute(
            constants.APPLICATION_ROUTE.CLIENT_PROJECT.EDIT.ROUTE,
            { clientId: data.client.id, id: data.id }
          ),
        });
        break;
      case constants.APPLICATION_ROUTE.CLIENT_PROJECT.LIST.STATUS:
        this.toggleClientProjectConfirmationHandler(data);
        break;
      case constants.APPLICATION_ROUTE.CLIENT_PROJECT.LIST.REMOVE_PROJECT:
        this.removeClientProjectConfirmationHandler(data);
        break;
      case constants.APPLICATION_ROUTE.CLIENT_PROJECT.LIST.MANAGE_CONTENT:
        this.props.history.push({
          pathname: formatRoute(
            constants.APPLICATION_ROUTE.CLIENT_PROJECT.MANAGE.ROUTE,
            { clientId: data.client.id, id: data.id }
          ),
        });
        break;
      case constants.APPLICATION_ROUTE.CLIENT_PROJECT.LIST.COPY_PROJECT:
        this.copyClientProjectConfirmationHandler(data);
        break;
      default:
        break;
    }
  };
  addProject = () => {
    this.props.history.push({
      pathname: formatRoute(
        constants.APPLICATION_ROUTE.CLIENT_PROJECT.CREATE.ROUTE,
        { clientId: this.state.clientId }
      ),
    });
  };
  handleClientChange = (e) => {
    let clients = [...this.props.client.clients];
    clients.forEach((c) => {
      c.is_selected = c.id === e.id ? true : false;
    });
    this.props.setClients(clients);
    let clientId = clients.find((c) => c.is_selected === true)
      ? clients.find((c) => c.is_selected === true).id
      : "all";
    this.setState(
      {
        clientId: clientId,
        pageNo: 1,
      },
      () => {
        this.getTableData();
      }
    );
  };
  changeRoute = () => {
    let queryParams = "";
    if (this.state.sortOrder) {
      queryParams =
        "&sort_order=" +
        this.state.sortOrder +
        "&sort_column=" +
        this.state.sortColumn;
    }
    if (this.state.name !== "") {
      queryParams +=
        "&search_column=name_or_email&search_value=" + this.state.name;
    }
    this.props.history.push({
      pathname: formatRoute(
        constants.APPLICATION_ROUTE.CLIENT_PROJECT.LIST.ROUTE,
        { clientId: this.state.clientId }
      ),
      search:
        "?page=" +
        this.state.pageNo +
        "&page_size=" +
        this.state.pageSize +
        queryParams,
    });
  };

  breadcrumbs = () => {
    let breadcrumbs = [
      {
        title: constants.APPLICATION_ROUTE.DASHBOARD.NAME,
        link: formatRoute(constants.APPLICATION_ROUTE.DASHBOARD.ROUTE, {}),
        is_active: false,
      },
      {
        title: constants.APPLICATION_ROUTE.PROJECT_GROUP.NAME,
        link: null,
        is_active: false,
      },
      {
        title: constants.APPLICATION_ROUTE.CLIENT_PROJECT.LIST.NAME,
        link: null,
        is_active: true,
      },
    ];
    return breadcrumbs;
  };

  render() {
    let clients =
      this.props.client && this.props.client.clients
        ? this.props.client.clients
        : [];

    return (
      <aside className="main_content">
        <div className="m-3 mb-0 bg-white px-3 py-3 br_8 main_content_height">
          <div className="d-flex align-items-center flex-wrap title-section breadcrumb-mb-0">
            <h1 className="text-ligntblue2 font-weight-bold mb-0">
              {constants.APPLICATION_ROUTE.CLIENT_PROJECT.LIST.HEADER_TITLE}
            </h1>
            <div className="ml-auto d-flex align-items-center justify-content-end title-right-block mb-0">
              <AclAction
                type="permissions"
                entity={constants.ACL.PROJECT_RESOURCE.TITLE}
                action={constants.ACL.PROJECT_RESOURCE.ACTIONS.CREATE}
              >
                <div tbrentitytype={constants.ACL.ENTITY_TYPE.DIV} className="">
                  <button
                    className={
                      "btn btn-primary d-flex align-items-center icon-text-primary-btn title-btn"
                    }
                    type="button"
                    onClick={this.addProject}
                  >
                    <span className="material-icons-round icon-fs-14 mr-1">
                      add
                    </span>
                    <span className="mt-text">
                      {" " +
                        constants.APPLICATION_ROUTE.CLIENT_PROJECT.LIST
                          .ADD_BUTTON_TITLE}
                    </span>
                  </button>
                </div>
              </AclAction>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-3 col-md-6 col-sm-6 col-12 clients_dropdown mb-18px">
              <div className="custom-combobox-list">
                <CustomDropdownList
                  data={clients}
                  handleChange={this.handleClientChange}
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 col-12 clients_dropdown mb-18px">
              <div className="w-100-search border-search search-box-shadow-2">
                <Search
                  handleSearch={this.handleSearch}
                  handleInput={this.handleInput}
                  value={this.state.name}
                  placeHolder={"Filter Name"}
                />
              </div>
            </div>
          </div>
          <div className="">
            <div className="custom-table-block custom-table-block-2 first-col-fixed-block nowrap-table-block th-nowrap sort-table-block center-th-align">
              <DynamicTableWithSelectAllComponentNew
                columnData={tableHeader}
                rowData={this.state.rowData}
                pagePrevious={this.pagePrevious}
                pageNext={this.pageNext}
                pageSize={this.state.pageSize}
                pageNo={this.state.pageNo}
                handlePage={this.handlePage}
                handleSort={this.handleSort}
                sortColumn={this.state.sortColumn}
                sortOrder={this.state.sortOrder}
                count={this.state.count}
                rowAction={this.rowAction}
                colSpan={tableHeader.filter((h) => h.visibility).length}
                isSelectAll={this.state.isSelectAll}
                selectedValues={this.state.selectedValues}
                handleSelectAll={this.handleSelectAll}
                handleCheckBoxButtonClick={this.onCheckboxBtnClick}
                showPagination={true}
                paginationClass={"center-pagination custom-pagination-new"}
                handlePageSize={this.handlePageSize}
                checkBoxDisable={this.state.disableStatus}
                statusId={this.state.statusId}
              />
            </div>

            {/* Tooltips for icons in table, don't delete */}
            {this.state.rowData.map((data) => {
              return (
                <>
                  <CustomReactTooltip
                    id={`tooltip-${Slugify(
                      constants.APPLICATION_ROUTE.CLIENT_PROJECT.LIST
                        .EDIT_PROJECT_TOOLTIP
                    )}-${data.id}`}
                    multiline={true}
                  />
                  <CustomReactTooltip
                    id={`tooltip-${Slugify(
                      constants.APPLICATION_ROUTE.CLIENT_PROJECT.LIST
                        .COPY_PROJECT_TOOLTIP
                    )}-${data.id}`}
                    multiline={true}
                  />
                  <CustomReactTooltip
                    id={`tooltip-${Slugify(
                      constants.APPLICATION_ROUTE.CLIENT_PROJECT.LIST
                        .REMOVE_PROJECT_TOOLTIP
                    )}-${data.id}`}
                    multiline={true}
                  />
                </>
              );
            })}

            <DailogNew
              isOpen={this.state.dailogModal}
              accept={this.dailogModalAccept}
              decline={this.dailogModalDecline}
              header={this.state.dailogModalHeader}
              content={this.state.dailogModalContent}
              config={this.state.dailogModalConfig}
              modalStyleType={this.state.dailogModalStyleType}
            />
          </div>
        </div>
      </aside>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return {
    activeMenuTab: (tab) => {
      dispatch(activeMenuTab(tab));
    },
    setClients: (allClients) => {
      dispatch(clients(allClients));
    },
  };
}
const mapStateToProps = (state) => {
  return {
    home: state.home,
    client: state.client,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListClientProjects);
