import React, { Component } from "react";
import { connect } from "react-redux";
import { categories, companies } from "../Store/Actions/CompanyDetails";
import TemplateParser from "../Components/TemplateParser";
import CustomDateTimePicker from "../Components/CustomDateTimePicker";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import constants from "../Utils/constants";
import DailogNew from "../Components/DailogNew";
import permissionCheck from "../lib/permissionCheck";
import TransactionDetailsComments from "../Containers/TransactionDetailsComments";
import decodeJWT from "../lib/decodeJWT";
import { formatRoute } from "react-router-named-routes";
import ScrollToTopBtn from "../Components/ScrollToTop";
import DocumentTitle from "../Utils/DocumentTitle";
import CompanyCustomDropdownList from "../Components/CompanyCustomDropdownList";
import Collapsible from "react-collapsible";
import CustomTitle from "../Components/CustomTitle";
import CustomTextarea from "../Components/CustomTextarea";

class TransactionDetailsContent extends Component {
  state = {
    titleModal: false,
    uid: null,
    placeholder: null,
    originalTitleValue: null,
    modifiedTitleValue: null,
    modifiedTitleStyle: null,
    dailogModal: false,
    dailogModalContent: "",
    dailogModalHeader: "",
    dailogModalConfig: {},
    dailogModalStyleType: "",
    imgLoading: true,
    imgLoadingError: false,
  };

  componentDidUpdate() {
    //
  }

  toggleTitleModal = (originalTitleValue, htmlStyle, uid) => {
    this.setState({
      titleModal: !this.state.titleModal,
      originalTitleValue: originalTitleValue,
      modifiedTitleValue: originalTitleValue,
      modifiedTitleStyle: htmlStyle,
      uid: uid,
    });
  };

  closeTitleModal = () => {
    this.setState({
      titleModal: false,
    });
  };

  saveTitleModal = () => {
    this.setState(
      {
        originalTitleValue: this.state.modifiedTitleValue,
      },
      () => {
        this.props.onSectionTitleChangeHandler(
          this.state.uid,
          this.state.originalTitleValue
        );
        this.closeTitleModal();
      }
    );
  };

  onTitleChangeHandler = (e) => {
    this.setState({
      modifiedTitleValue: e.target.value,
    });
  };

  confirmDailog = (uid) => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">Delete</h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="remove-body">
          <h2 className="font-weight-semibold mb-0">
            {
              constants.MANAGE_CONTENT_TRANSACTION_DETAILS
                .DELETE_CONTENT_CONFIRM_MESSAGE
            }
          </h2>
        </div>
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: { type: "section", uid: uid },
      dailogModalStyleType: "danger-modal",
    });
  };

  confirmDailogUndo = (uid) => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">Undo</h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="inactive-body">
          <h2 className="font-weight-semibold mb-0">
            {
              constants.MANAGE_CONTENT_TRANSACTION_DETAILS
                .UNDO_DELETE_ACTION_CONFIRM_MESSAGE
            }
          </h2>
        </div>
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: { type: "section_recover", uid: uid },
      dailogModalStyleType: "inactive-modal",
    });
  };

  dailogModalAccept = (config) => {
    if (config.type === "section") {
      this.props.removeContent(config.uid);
      this.setState({
        dailogModal: false,
        dailogModalHeader: "",
        dailogModalContent: "",
        dailogModalConfig: {},
        dailogModalStyleType: "",
      });
    } else if (config.type === "section_recover") {
      this.props.undoRemoveContent(config.uid);
      this.setState({
        dailogModal: false,
        dailogModalHeader: "",
        dailogModalContent: "",
        dailogModalConfig: {},
        dailogModalStyleType: "",
      });
    }
  };

  dailogModalDecline = () => {
    this.setState({
      dailogModal: false,
      dailogModalContent: "",
      dailogModalConfig: {},
    });
  };

  handleImageLoad = () => {
    // this.setState({ imgLoading: false,imgLoadingError: false });
    setTimeout(() => {
      this.setState({ imgLoading: false, imgLoadingError: false });
    }, 1000);
  };

  handleImageError = () => {
    this.setState({ imgLoading: false, imgLoadingError: true });
  };

  breadcrumbs = () => {
    let nextStates =
      this.props.company_details &&
      this.props.company_details.transactionDetails &&
      this.props.company_details.transactionDetails.next_states
        ? this.props.company_details.transactionDetails.next_states
        : [];
    let companyDetails =
      this.props.company_details &&
      this.props.company_details.transactionDetails
        ? this.props.company_details.transactionDetails
        : {};
    let breadcrumbs = [
      {
        title: constants.APPLICATION_ROUTE.DASHBOARD.NAME,
        link: formatRoute(constants.APPLICATION_ROUTE.DASHBOARD.ROUTE, {}),
        is_active: false,
      },
      {
        title: constants.APPLICATION_ROUTE.COMPANY_GROUP.NAME,
        link: null,
        is_active: false,
      },
      {
        title: constants.APPLICATION_ROUTE.COMPANY.LIST.NAME,
        link: formatRoute(constants.APPLICATION_ROUTE.COMPANY.LIST.ROUTE, {}),
        is_active: false,
      },
      {
        title: nextStates.includes(
          constants.MANAGE_CONTENT_PERMISSIONS.DRAFT.SLUG
        )
          ? constants.APPLICATION_ROUTE.MANAGE_CONTENT_MY_DRAFTS.NAME
          : constants.APPLICATION_ROUTE.MANAGE_CONTENT_TRACK_SUBMISSIONS.NAME,
        link: formatRoute(
          nextStates.includes(constants.MANAGE_CONTENT_PERMISSIONS.DRAFT.SLUG)
            ? constants.APPLICATION_ROUTE.MANAGE_CONTENT_MY_DRAFTS.ROUTE
            : constants.APPLICATION_ROUTE.MANAGE_CONTENT_TRACK_SUBMISSIONS
                .ROUTE,
          {}
        ),
        is_active: false,
      },
      {
        title: `${
          companyDetails.company_segment &&
          companyDetails.company_segment.company &&
          companyDetails.company_segment.company.id &&
          companyDetails.company_segment.segment &&
          companyDetails.company_segment.segment.id
            ? `${companyDetails.company_segment.company.name} - ${companyDetails.company_segment.segment.name}`
            : ""
        }`,
        link: null,
        is_active: true,
      },
    ];
    DocumentTitle(
      `${
        companyDetails.company_segment &&
        companyDetails.company_segment.company &&
        companyDetails.company_segment.company.id &&
        companyDetails.company_segment.segment &&
        companyDetails.company_segment.segment.id
          ? `${companyDetails.company_segment.company.name} - ${companyDetails.company_segment.segment.name}`
          : ""
      }`
    );
    return breadcrumbs;
  };
  handleSubcateogryChange(e) {}

  render() {
    let companyContent =
      this.props.company_details && this.props.company_details.companyContent
        ? this.props.company_details.companyContent
        : [];
    let categories =
      this.props.company_details && this.props.company_details.categories
        ? this.props.company_details.categories
        : [];
    let transactionComments =
      this.props.company_details &&
      this.props.company_details.transactionDetails &&
      this.props.company_details.transactionDetails.comments
        ? this.props.company_details.transactionDetails.comments
        : [];
    let transactionComment =
      this.props.company_details &&
      this.props.company_details.transactionDetails &&
      this.props.company_details.transactionDetails.comment
        ? this.props.company_details.transactionDetails.comment
        : null;
    let transactionCommentOpenFlag =
      this.props.company_details &&
      this.props.company_details.transactionDetails &&
      this.props.company_details.transactionDetails.openCommentsFlag
        ? this.props.company_details.transactionDetails.openCommentsFlag
        : false;
    let transactionId =
      this.props.company_details &&
      this.props.company_details.transactionDetails &&
      this.props.company_details.transactionDetails.id
        ? this.props.company_details.transactionDetails.id
        : "";
    let nextStates =
      this.props.company_details &&
      this.props.company_details.transactionDetails &&
      this.props.company_details.transactionDetails.next_states
        ? this.props.company_details.transactionDetails.next_states
        : [];
    let nextStateRole =
      this.props.company_details &&
      this.props.company_details.transactionDetails &&
      this.props.company_details.transactionDetails.role_state &&
      this.props.company_details.transactionDetails.role_state.role &&
      this.props.company_details.transactionDetails.role_state.role.short_name
        ? this.props.company_details.transactionDetails.role_state.role
            .short_name
        : null;
    let nextState =
      this.props.company_details &&
      this.props.company_details.transactionDetails &&
      this.props.company_details.transactionDetails.role_state &&
      this.props.company_details.transactionDetails.role_state.state &&
      this.props.company_details.transactionDetails.role_state.state.short_name
        ? this.props.company_details.transactionDetails.role_state.state
            .short_name
        : null;
    let tokenDetails = decodeJWT(localStorage.getItem("token"));
    let default_categories = categories.filter((row) => row.is_default === 0);
    let subcategories = [];
    default_categories.forEach((row) => {
      subcategories.push(
        ...row.subcategories.map((row1) => {
          return {
            id: row1.id,
            name: row1.name,
            is_selected: false,
            value: row1.id,
          };
        })
      );
    });

    return Object.keys(tokenDetails).length ? (
      <aside className="dash_board_content inner-sidebar-right-content">
        {categories && categories.length > 0 ? (
          <>
            <div className="row company_grph">
              <div className="col-xl-12">
                <div className="company_ttl">
                  <ScrollToTopBtn />
                  <div className="row mb-10">
                    {!this.props.currentState?.short_name?.includes(
                      "draft"
                    ) && (
                      <div className="col-12 col-md-7 mb-3">
                        <div
                          className="d-flex align-items-center border"
                          style={{ minHeight: "70px" }}
                        >
                          <div className="col-md-2">
                            <img
                              src={
                                this.state.imgLoading
                                  ? constants.COMPANY_DETAILS.IMAGE_LOADING
                                  : this.props.setImage ??
                                    constants.COMPANY_DETAILS
                                      .NO_IMAGE_FOUND_PATH
                              }
                              alt="Uploaded"
                              style={{
                                maxWidth: "100%",
                                width: "50px",
                                height: "50px",
                              }}
                              onLoad={this.handleImageLoad}
                              onError={this.handleImageError}
                            />
                          </div>
                          <div className={"col-md-10"}>
                            <div className="d-flex justify-content-between align-items-center">
                              <label
                                style={{
                                  whiteSpace: "pre-wrap",
                                  maxHeight: "60px",
                                  overflowY: "auto",
                                  wordBreak: "break-word",
                                  color: this.props.setQuote
                                    ? ""
                                    : constants.QUOTE_IMAGE
                                        .NO_QUOTE_FOUND_COLOR,
                                  minWidth: "420px",
                                }}
                              >
                                {this.props.setQuote
                                  ? this.props.setQuote
                                  : constants.QUOTE_IMAGE.NO_QUOTE_FOUND}
                              </label>
                              {this.props.is_editable && (
                                <button
                                  onClick={() => this.props.onEditClicked()}
                                  className="dynamicTableActionButton btn btn-md d-inline-flex align-items-center"
                                >
                                  <span className="material-icons-outlined text-blue-clr icon-fs-20">
                                    edit
                                  </span>
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div
                      className={
                        this.props.currentState?.short_name?.includes("draft")
                          ? "col-12 col-md-12 d-flex align-items-center justify-content-end"
                          : "col-12 col-md-5 d-flex align-items-center justify-content-end"
                      }
                    >
                      {this.props.is_editable && (
                        <button
                          className={
                            "btn btn-custom btn-danger icon-text-btn title-btn d-inline-flex align-items-center mb-3"
                          }
                          type="button"
                          onClick={this.props.resetContent}
                        >
                          {" Reset"}
                          <span className="material-icons-outlined icon-fs-16 ml-1">
                            restart_alt
                          </span>
                        </button>
                      )}
                      {nextStates.includes(
                        constants.MANAGE_CONTENT_PERMISSIONS.REJECT.SLUG
                      ) &&
                        permissionCheck(
                          constants.MANAGE_CONTENT_PERMISSIONS.KEY,
                          constants.MANAGE_CONTENT_PERMISSIONS.ENTITY,
                          constants.MANAGE_CONTENT_PERMISSIONS.REJECT.SLUG
                        ) &&
                        companyContent.length > 0 && (
                          <button
                            className={
                              "btn btn-custom btn-danger icon-text-btn title-btn d-inline-flex align-items-center mb-3 mx-3"
                            }
                            type="button"
                            onClick={() => {
                              this.props.saveContent(
                                constants.MANAGE_CONTENT_PERMISSIONS.REJECT.SLUG
                              );
                            }}
                          >
                            {" " +
                              constants.MANAGE_CONTENT_PERMISSIONS.REJECT
                                .BUTTON_TITLE}
                            <span className="material-icons-round icon-fs-16 ml-1">
                              close
                            </span>
                          </button>
                        )}
                      {nextStates.includes(
                        constants.MANAGE_CONTENT_PERMISSIONS.DRAFT.SLUG
                      ) &&
                        permissionCheck(
                          constants.MANAGE_CONTENT_PERMISSIONS.KEY,
                          constants.MANAGE_CONTENT_PERMISSIONS.ENTITY,
                          constants.MANAGE_CONTENT_PERMISSIONS.DRAFT.SLUG
                        ) &&
                        companyContent.length > 0 && (
                          <button
                            className={
                              "btn btn-custom btn-success icon-text-btn title-btn d-inline-flex align-items-center mb-3 mx-3"
                            }
                            type="button"
                            onClick={() => {
                              this.props.saveContent(
                                constants.MANAGE_CONTENT_PERMISSIONS.DRAFT.SLUG
                              );
                            }}
                          >
                            {" " +
                              constants.MANAGE_CONTENT_PERMISSIONS.DRAFT
                                .BUTTON_TITLE}
                            <span className="material-icons-outlined icon-fs-16 ml-1">
                              save
                            </span>
                          </button>
                        )}
                      {nextStates.includes(
                        constants.MANAGE_CONTENT_PERMISSIONS.SUBMIT.SLUG
                      ) &&
                        permissionCheck(
                          constants.MANAGE_CONTENT_PERMISSIONS.KEY,
                          constants.MANAGE_CONTENT_PERMISSIONS.ENTITY,
                          constants.MANAGE_CONTENT_PERMISSIONS.SUBMIT.SLUG
                        ) &&
                        companyContent.length > 0 && (
                          <button
                            className={
                              "btn btn-custom btn-primary icon-text-btn title-btn d-inline-flex align-items-center mb-3"
                            }
                            type="button"
                            onClick={() => {
                              this.props.saveContent(
                                constants.MANAGE_CONTENT_PERMISSIONS.SUBMIT.SLUG
                              );
                            }}
                          >
                            {" " +
                              constants.MANAGE_CONTENT_PERMISSIONS.SUBMIT
                                .BUTTON_TITLE}
                            <span className="material-icons-outlined icon-fs-16 ml-1">
                              done_all
                            </span>
                          </button>
                        )}
                      {nextStates.includes(
                        constants.MANAGE_CONTENT_PERMISSIONS.RE_SUBMIT.SLUG
                      ) &&
                        permissionCheck(
                          constants.MANAGE_CONTENT_PERMISSIONS.KEY,
                          constants.MANAGE_CONTENT_PERMISSIONS.ENTITY,
                          constants.MANAGE_CONTENT_PERMISSIONS.RE_SUBMIT.SLUG
                        ) &&
                        companyContent.length > 0 && (
                          <button
                            className={
                              "btn btn-custom btn-primary icon-text-btn title-btn d-inline-flex align-items-center mb-3 ml-3"
                            }
                            type="button"
                            onClick={() => {
                              this.props.saveContent(
                                constants.MANAGE_CONTENT_PERMISSIONS.RE_SUBMIT
                                  .SLUG
                              );
                            }}
                          >
                            {" " +
                              constants.MANAGE_CONTENT_PERMISSIONS.RE_SUBMIT
                                .BUTTON_TITLE}
                            <span className="material-icons-outlined icon-fs-16 ml-1">
                              done_all
                            </span>
                          </button>
                        )}
                      {tokenDetails.role.short_name !==
                        constants.USER_ROLES.EDITOR.SLUG &&
                        nextStates.includes(
                          constants.MANAGE_CONTENT_PERMISSIONS.APPROVE.SLUG
                        ) &&
                        permissionCheck(
                          constants.MANAGE_CONTENT_PERMISSIONS.KEY,
                          constants.MANAGE_CONTENT_PERMISSIONS.ENTITY,
                          constants.MANAGE_CONTENT_PERMISSIONS.APPROVE.SLUG
                        ) &&
                        companyContent.length > 0 && (
                          <button
                            className={
                              "btn btn-custom btn-success icon-text-btn title-btn d-inline-flex align-items-center mb-3"
                            }
                            type="button"
                            onClick={() => {
                              this.props.saveContent(
                                nextState ===
                                  constants.MANAGE_CONTENT_PERMISSIONS.REVIEW
                                    .SLUG &&
                                  nextStateRole ===
                                    constants.USER_ROLES.EDITOR.SLUG &&
                                  tokenDetails.role.short_name ===
                                    constants.USER_ROLES.ADMIN.SLUG
                                  ? this.props.changesMade
                                    ? constants.MANAGE_CONTENT_PERMISSIONS
                                        .UPDATE.SLUG
                                    : constants.MANAGE_CONTENT_PERMISSIONS
                                        .PUBLISH.SLUG
                                  : constants.MANAGE_CONTENT_PERMISSIONS.APPROVE
                                      .SLUG
                              );
                            }}
                          >
                            {" " +
                              (nextState ===
                                constants.MANAGE_CONTENT_PERMISSIONS.REVIEW
                                  .SLUG &&
                              nextStateRole ===
                                constants.USER_ROLES.EDITOR.SLUG &&
                              tokenDetails.role.short_name ===
                                constants.USER_ROLES.ADMIN.SLUG
                                ? this.props.changesMade
                                  ? constants.MANAGE_CONTENT_PERMISSIONS.UPDATE
                                      .BUTTON_TITLE
                                  : constants.MANAGE_CONTENT_PERMISSIONS.PUBLISH
                                      .BUTTON_TITLE
                                : constants.MANAGE_CONTENT_PERMISSIONS.APPROVE
                                    .BUTTON_TITLE)}
                            <span className="material-icons-outlined icon-fs-16 ml-1">
                              done_all
                            </span>
                          </button>
                        )}
                      {tokenDetails.role.short_name ===
                        constants.USER_ROLES.EDITOR.SLUG &&
                        nextStates.includes(
                          constants.MANAGE_CONTENT_PERMISSIONS.APPROVE.SLUG
                        ) &&
                        permissionCheck(
                          constants.MANAGE_CONTENT_PERMISSIONS.KEY,
                          constants.MANAGE_CONTENT_PERMISSIONS.ENTITY,
                          constants.MANAGE_CONTENT_PERMISSIONS.APPROVE.SLUG
                        ) &&
                        companyContent.length > 0 && (
                          <button
                            className={
                              "btn btn-custom btn-success icon-text-btn title-btn d-inline-flex align-items-center mb-3"
                            }
                            type="button"
                            onClick={() => {
                              this.props.saveContent(
                                this.props.changesMade
                                  ? constants.MANAGE_CONTENT_PERMISSIONS.UPDATE
                                      .SLUG
                                  : constants.MANAGE_CONTENT_PERMISSIONS.PUBLISH
                                      .SLUG
                              );
                            }}
                          >
                            {this.props.changesMade
                              ? constants.MANAGE_CONTENT_PERMISSIONS.UPDATE
                                  .BUTTON_TITLE
                              : constants.MANAGE_CONTENT_PERMISSIONS.PUBLISH
                                  .BUTTON_TITLE}
                            <span className="material-icons-outlined icon-fs-16 ml-1">
                              done_all
                            </span>
                          </button>
                        )}
                    </div>
                  </div>
                </div>
              </div>
              {this.props.showComments &&
                (transactionComments.length > 0 ||
                  this.props.writeComments) && (
                  <div className="col-xl-12">
                    <TransactionDetailsComments
                      writeComments={this.props.writeComments}
                      uid={transactionId}
                      comments={transactionComments}
                      key={transactionId}
                      openCommentsFlag={transactionCommentOpenFlag}
                      comment={transactionComment}
                      onCommentChangeHandler={
                        this.props.onTransactionCommentChangeHandler
                      }
                      toggleCommentsHandler={
                        this.props.toggleCommentsHandlerTransaction
                      }
                    />
                  </div>
                )}
            </div>
            {categories.map((category, index) =>
              companyContent &&
              companyContent.length > 0 &&
              companyContent.filter((segment) =>
                category.subcategories.find(
                  (value) => value.id === segment.subcategory_id
                )
              ).length > 0 ? (
                <div className="custom-collapser custom-collapser2 company-details-collapser details-page-collapser">
                  <div className="category-wrapper">
                    <Collapsible
                      open={true}
                      trigger={
                        <div className="collapse-trigger">
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center">
                              <CustomTitle
                                title={category.name}
                                title_html={category.name_html}
                                tag="h3"
                                className="font-weight-bold mb-0 title category_name"
                              />
                            </div>
                            <div className="d-flex align-items-center">
                              <div className="collapse-icon-wrapper">
                                <span className="material-icons-round add icon">
                                  add
                                </span>
                                <span className="material-icons-round remove icon">
                                  remove
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      }
                    >
                      {category.subcategories.map(
                        (sub_category, sub_index) =>
                          companyContent &&
                          companyContent.length > 0 &&
                          companyContent.filter(
                            (segment) =>
                              segment.subcategory_id === sub_category.id
                          ).length > 0 && (
                            <div
                              onMouseOver={() =>
                                this.props.mouseScroll(sub_category.id)
                              }
                              className={`${
                                sub_category.mode ? sub_category.mode : ""
                              } sub-category-section`}
                            >
                              <div
                                className="tbr_position inner-content-section"
                                key={sub_index}
                              >
                                <div
                                  id={`${sub_category.name}-${sub_category.id}`}
                                  style={{ "scroll-margin-top": "65px" }}
                                  className=""
                                >
                                  <div className="sub_category_heading  d-flex align-items-center">
                                    <div className="heading mb-0 sub_category_heading_title">
                                      <CustomTitle
                                        title={sub_category.name}
                                        title_html={sub_category.name_html}
                                        tag="h3"
                                        className="heading mb-0 sub_category_heading_title ellipsis"
                                      />
                                    </div>
                                    {category.is_default === 1 &&
                                      companyContent.find(
                                        (segment) =>
                                          segment.subcategory_id ===
                                            sub_category.id &&
                                          !segment.is_deleted
                                      ) && (
                                        <div className="custom-combobox-list box-shadow-none min-width ml-auto mr-5 align-self-center">
                                          <CompanyCustomDropdownList
                                            data={subcategories}
                                            id={sub_category.id}
                                            onChangeHandler={
                                              this.props
                                                .subcateogoryOnChangeHandler
                                            }
                                            placeholder={"Select Subcategory"}
                                          />
                                        </div>
                                      )}
                                    {sub_category.mode !== "news" &&
                                      companyContent.filter(
                                        (segment) =>
                                          segment.subcategory_id ===
                                            sub_category.id &&
                                          !segment.is_deleted
                                      )[0] && (
                                        <>
                                          {companyContent.filter(
                                            (segment) =>
                                              segment.subcategory_id ===
                                                sub_category.id &&
                                              !segment.is_deleted
                                          )[0].published_date && (
                                            <>
                                              <div className="d-flex align-items-center">
                                                <h5 className="font-weight-semibold text-black2 mb-0">
                                                  Published
                                                </h5>
                                                <div className="custom-date-time-picker ml-10px min-width-200">
                                                  <CustomDateTimePicker
                                                    isDisabled={
                                                      !this.props.is_editable
                                                    }
                                                    id={
                                                      companyContent.filter(
                                                        (segment) =>
                                                          segment.subcategory_id ===
                                                            sub_category.id &&
                                                          !segment.is_deleted
                                                      )[0].uid
                                                    }
                                                    date={
                                                      companyContent.filter(
                                                        (segment) =>
                                                          segment.subcategory_id ===
                                                            sub_category.id &&
                                                          !segment.is_deleted
                                                      )[0].published_date
                                                    }
                                                    onChangeHandler={
                                                      this.props
                                                        .dateOnChangeHandler
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            </>
                                          )}
                                        </>
                                      )}
                                    {this.props.is_editable &&
                                      sub_category.mode === "news" && (
                                        <>
                                          {!companyContent.filter(
                                            (segment) =>
                                              segment.subcategory_id ===
                                              sub_category.id
                                          )[0].is_deleted && (
                                            <div
                                              className="del-undo-icon-wrapper d-inline-flex"
                                              onClick={() => {
                                                this.confirmDailog(
                                                  companyContent.filter(
                                                    (segment) =>
                                                      segment.subcategory_id ===
                                                      sub_category.id
                                                  )[0].uid
                                                );
                                              }}
                                            >
                                              <span className="material-icons-outlined icon icon-fs-17">
                                                delete_outline
                                              </span>
                                            </div>
                                          )}
                                          {companyContent.filter(
                                            (segment) =>
                                              segment.subcategory_id ===
                                              sub_category.id
                                          )[0].is_deleted && (
                                            <div
                                              className="del-undo-icon-wrapper d-inline-flex"
                                              onClick={() => {
                                                this.confirmDailogUndo(
                                                  companyContent.filter(
                                                    (segment) =>
                                                      segment.subcategory_id ===
                                                      sub_category.id
                                                  )[0].uid
                                                );
                                              }}
                                            >
                                              <span className="material-icons-outlined icon icon-fs-17">
                                                undo
                                              </span>
                                            </div>
                                          )}
                                        </>
                                      )}
                                  </div>
                                </div>

                                {companyContent
                                  .filter(
                                    (segment) =>
                                      segment.subcategory_id === sub_category.id
                                  )
                                  .map((sub_category1, sub_index1) => (
                                    <div
                                      key={sub_category1.uid + "-" + sub_index1}
                                      className={
                                        sub_category1.subcategory_id ===
                                        this.props.selectedSlide
                                          ? "content-section-selected"
                                          : "content-section"
                                      }
                                    >
                                      {
                                        <div
                                          className="title d-flex align-items-center justify-content-between"
                                          key={
                                            sub_category1.uid + "-" + sub_index1
                                          }
                                        >
                                          <div
                                            className={`${
                                              sub_category1.is_deleted
                                                ? "content_deleted"
                                                : ""
                                            } text-primary mb-0 d-inline-flex align-items-center`}
                                          >
                                            {!sub_category1.is_deleted &&
                                              this.props.is_editable && (
                                                <>
                                                  <span
                                                    className="material-icons manage_content_action icon-fs-16 mr-1"
                                                    onClick={() => {
                                                      this.toggleTitleModal(
                                                        sub_category1.subcategory_title,
                                                        sub_category1.subcategory_title_html,
                                                        sub_category1.uid
                                                      );
                                                    }}
                                                  >
                                                    edit
                                                  </span>
                                                  &nbsp;
                                                </>
                                              )}
                                            <CustomTitle
                                              title={
                                                sub_category1.subcategory_title
                                                  ? sub_category1.subcategory_title
                                                  : constants.COMPANY_DETAILS
                                                      .TITLE_PLACE_HOLDER
                                              }
                                              title_html={
                                                sub_category1.subcategory_title_html
                                              }
                                              tag="h5"
                                              className={`${
                                                sub_category1.is_deleted
                                                  ? "content_deleted"
                                                  : ""
                                              } text-primary mb-0 d-inline-flex align-items-center`}
                                            />
                                          </div>
                                          {this.props.is_editable &&
                                            sub_category.mode !== "news" && (
                                              <>
                                                {!sub_category1.is_deleted && (
                                                  <div
                                                    className="del-undo-icon-wrapper d-inline-flex"
                                                    onClick={() => {
                                                      this.confirmDailog(
                                                        sub_category1.uid
                                                      );
                                                    }}
                                                  >
                                                    <span className="material-icons-outlined icon icon-fs-17">
                                                      delete_outline
                                                    </span>
                                                  </div>
                                                )}
                                                {sub_category1.is_deleted && (
                                                  <div
                                                    className="del-undo-icon-wrapper d-inline-flex"
                                                    onClick={() => {
                                                      this.confirmDailogUndo(
                                                        sub_category1.uid
                                                      );
                                                    }}
                                                  >
                                                    <span className="material-icons-outlined icon icon-fs-17">
                                                      undo
                                                    </span>
                                                  </div>
                                                )}
                                              </>
                                            )}
                                        </div>
                                      }
                                      <div className="content_section template-content-section">
                                        <TemplateParser
                                          emptyContentMessage={
                                            sub_category.mode !== "news"
                                              ? constants.COMPANY_DETAILS
                                                  .CONTENT_PLACE_HOLDER
                                              : constants.COMPANY_DETAILS
                                                  .NEWS_PLACE_HOLDER
                                          }
                                          isEditable={this.props.is_editable}
                                          isDeleted={sub_category1.is_deleted}
                                          key={`temp-${sub_category1.uid}`}
                                          template={
                                            sub_category1.template.content
                                          }
                                          content={sub_category1.data}
                                          uid={sub_category1.uid}
                                          onContentChangeHandler={
                                            this.props.onContentChangeHandler
                                          }
                                        />
                                      </div>
                                      {this.props.showComments &&
                                        (sub_category1.comments.length > 0 ||
                                          this.props.writeComments) && (
                                          <TransactionDetailsComments
                                            writeComments={
                                              this.props.writeComments
                                            }
                                            uid={sub_category1.uid}
                                            comments={sub_category1.comments}
                                            key={sub_category1.uid}
                                            openCommentsFlag={
                                              sub_category1.openCommentsFlag
                                            }
                                            comment={sub_category1.comment}
                                            onCommentChangeHandler={
                                              this.props.onCommentChangeHandler
                                            }
                                            toggleCommentsHandler={
                                              this.props.toggleCommentsHandler
                                            }
                                          />
                                        )}
                                    </div>
                                  ))}
                              </div>
                            </div>
                          )
                      )}
                    </Collapsible>
                  </div>
                </div>
              ) : null
            )}
            {companyContent && companyContent.length ? (
              ""
            ) : (
              <h4>No content found</h4>
            )}
            <Modal
              isOpen={this.state.titleModal}
              toggle={this.closeTitleModal}
              className={
                "modal-lg custom-modal primary-modal modal-without-header"
              }
            >
              <ModalBody>
                <CustomTextarea
                  modifiedTitleValue={this.state.modifiedTitleValue}
                  modifiedTitleStyle={this.state.modifiedTitleStyle}
                  onTitleChangeHandler={this.onTitleChangeHandler}
                />
              </ModalBody>
              <ModalFooter>
                <Button
                  className={"btn-outline-primary modal-btn"}
                  onClick={this.closeTitleModal}
                >
                  Cancel
                </Button>{" "}
                <Button
                  className={"modal-right-btn modal-btn"}
                  onClick={this.saveTitleModal}
                >
                  Save
                </Button>
              </ModalFooter>
            </Modal>
            <DailogNew
              isOpen={this.state.dailogModal}
              accept={this.dailogModalAccept}
              decline={this.dailogModalDecline}
              header={this.state.dailogModalHeader}
              content={this.state.dailogModalContent}
              config={this.state.dailogModalConfig}
              modalStyleType={this.state.dailogModalStyleType}
            />
          </>
        ) : (
          <h4>No content found</h4>
        )}
      </aside>
    ) : (
      ""
    );
  }
}

const mapStateToProps = (state) => {
  return {
    company_details: state.company_details,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    categories: (payload) => {
      dispatch(categories(payload));
    },
    companies: (payload) => {
      dispatch(companies(payload));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransactionDetailsContent);
