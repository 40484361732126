import React, { Component } from "react";
import constants from "../Utils/constants";
import CustomPagination from "./Pagination";

class DynamicTableWithSelectAllComponentNew extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  totalPages = () => {
    if (this.props.count === 0) {
      return 1;
    }
    return parseInt(Math.ceil(this.props.count / this.props.pageSize));
  };
  getPagingRange = (current, { min = 1, total = 20, length = 5 } = {}) => {
    if (length > total) length = total;
    let start = current - Math.floor(length / 2);
    start = Math.max(start, min);
    start = Math.min(start, min + total - length);
    return Array.from({ length: length }, (el, i) => start + i);
  };
  entireRowAction = (row, e) => {
    if (this.props.entireRowAction && this.props.entireRowAction.title) {
      this.props.rowAction(this.props.entireRowAction.title, row, e);
    }
  };
  handlePageSize = (e) => {
    this.props.handlePageSize(e);
  };
  render() {
    return (
      <>
        <div className="responsive-table scroll-table-wrapper">
          <table
            className={`nowrap responsive ${
              this.props.className ? this.props.className : ""
            } ${
              this.props.statusId !== 3 && this.props.checkboxpermission
                ? "table-with-checkboxs"
                : ""
            }`}
            cellSpacing="0"
            width="100%"
          >
            <thead>
              <tr style={{ backgroundColor: "#4d88c9" }}>
                {this.props.statusId !== 3 && this.props.checkboxpermission && (
                  <th>
                    <div className="d-flex align-items-center all_checkbox">
                      <input
                        className="custom-checkbox-new"
                        type="checkbox"
                        name="isSelectAll"
                        disabled={this.props.checkBoxDisable}
                        value={this.props.isSelectAll}
                        checked={this.props.isSelectAll}
                        onClick={(e) => this.props.handleSelectAll(e)}
                        style={{ verticalAlign: "middle" }}
                      />
                    </div>
                  </th>
                )}
                {this.props.columnData.map((column, key) =>
                  column.visibility ? (
                    <th
                      key={key}
                      className={`${
                        this.props.sortColumn === column.id
                          ? this.props.sortOrder
                          : column.sortable
                          ? "default"
                          : null
                      } ${column.headerClass ? column.headerClass : null}`}
                      onClick={() => {
                        column.sortable &&
                          this.props.handleSort(column.id, key);
                      }}
                      style={column.headerStyle}
                    >
                      <div className="th-content">{column.title}</div>
                    </th>
                  ) : null
                )}
              </tr>
            </thead>
            <tbody>
              {this.props.rowData.length > 0 &&
                this.props.rowData.map((row, index) => {
                  return (
                    <tr
                      key={index}
                      onClick={(e) => {
                        this.entireRowAction(row, e);
                      }}
                    >
                      {this.props.statusId !== 3 &&
                        this.props.checkboxpermission && (
                          <td key={index - 0}>
                            <input
                              className="custom-checkbox-new"
                              type="checkbox"
                              checked={
                                this.props.selectedValues.filter(
                                  (val) => val.id === row.id
                                ).length > 0
                              }
                              name="selectedValues"
                              onClick={(e) =>
                                this.props.handleCheckBoxButtonClick(e, row)
                              }
                              style={{ verticalAlign: "sub" }}
                            />
                          </td>
                        )}

                      {this.props.columnData.map((val, i) =>
                        val.visibility ? (
                          <td
                            key={`${index + "-" + i + 1}`}
                            style={{ ...val.rowStyle, backgroundColor: "#fff" }}
                            className={val.rowClass ? val.rowClass : null}
                          >
                            {row[val.id] ? row[val.id] : "-"}
                          </td>
                        ) : null
                      )}
                    </tr>
                  );
                })}
              {this.props.rowData.length === 0 && (
                <tr>
                  <td
                    className={`no-records text-center`}
                    colSpan={this.props.colSpan}
                  >
                    {constants.PAGINATION.NO_RECORDS_FOUND}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {this.props.showPagination && (
          <>
            <CustomPagination
              handlePage={this.props.handlePage}
              totalPages={this.totalPages}
              handlePageSize={this.handlePageSize}
              pageSize={this.props.pageSize}
              pageNo={this.props.pageNo}
              // className="right-pagination custom-pagination-new"
              className={`${this.props.paginationClass}`}
            />
          </>
        )}
      </>
    );
  }
}

export default DynamicTableWithSelectAllComponentNew;
