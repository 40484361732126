import React, { Component } from "react";
import DynamicTableComponentNew from "../../Components/DynamicTableComponentsNew";
import constants from "../../Utils/constants";
import { ManageContentServices } from "../../Services/ManageContent";
import { formatRoute } from "react-router-named-routes";
import ComapnyDetailsDateTimeFormatter from "../../Utils/ComapnyDetailsDateTimeFormatter";
import Search from "../../Components/Search";
import { connect } from "react-redux";
import decodeJWT from "../../lib/decodeJWT";
import CustomReactTooltip from "../../Components/CustomReactTooltip";
import Toast from "../../Utils/Toast";
import CustomEllipsis from "../../Utils/CustomEllipsis";

const tableHeader = [
  {
    id: "eco_system_name",
    title: "Ecosystem",
    sortable: true,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: {
      color: "#99a0aa",
      textTransform: "capitalize",
      textAlign: "left",
    },
    visibility: true,
  },
  {
    id: "status",
    title: "Current Status",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    visibility: true,
  },
  {
    id: "created_by",
    title: "Created By",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    visibility: true,
  },
  {
    id: "created_at",
    title: "Created On",
    sortable: true,
    headerStyle: { textAlign: "left" },
    rowStyle: { textAlign: "left" },
    visibility: true,
  },
  {
    id: "updated_at",
    title: "Last Modified At",
    sortable: true,
    headerStyle: { textAlign: "left" },
    rowStyle: { textAlign: "left" },
    visibility: true,
  },
  {
    id: "actions",
    title: "Actions",
    sortable: false,
    headerClass: "text-center",
    rowStyle: { textAlign: "center" },
    visibility: true,
  },
];
const showActions = false;
class ListEcosystemManageContentTransaction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowData: [],
      sortColumn: constants.PAGINATION.ECOSYSTEM_TRACK_DEFAULT_SORT_COLUMN,
      sortOrder: constants.PAGINATION.DEFAULT_SORT_ORDER,
      pageNo: this.props.pageNo,
      pageSize: this.props.pageSize,
      name: "",
    };
  }
  componentDidMount() {
    this.getTableData();
  }
  pagePrevious = () => {
    this.setState(
      (prevState) => ({
        pageNo: prevState.pageNo - 1,
      }),
      () => this.getTableData()
    );
  };
  pageNext = () => {
    this.setState(
      (prevState) => ({
        pageNo: prevState.pageNo + 1,
      }),
      () => this.getTableData()
    );
  };
  handlePage = (input) => {
    this.setState({ pageNo: input }, () => this.getTableData());
  };
  handleSort = (clickedColumn, key) => {
    this.setState(
      {
        sortColumn: clickedColumn,
        segmentSort: false,
        sortOrder: this.state.sortOrder === "desc" ? "asc" : "desc",
      },
      () => this.getTableData()
    );
  };
  handleSearch = () => {
    this.setState({ pageNo: 1 }, () => {
      this.getTableData();
    });
  };
  extractCategories = (categories = {}) => {
    let subcategories = [];
    Object.keys(categories).forEach((i) => {
      subcategories.push(...categories[i]);
    });
    return subcategories.join(", ");
  };
  getNextAction = (user, latest_role, latest_state, data) => {
    let loggedInUserId =
      this.props.home &&
      this.props.home.loginUserSuccess &&
      this.props.home.loginUserSuccess.id
        ? this.props.home.loginUserSuccess.id
        : null;
    let tokenDetails = decodeJWT(localStorage.getItem("token"));
    let loggedInRole =
      tokenDetails && tokenDetails.role && tokenDetails.role.name
        ? tokenDetails.role.name
        : null;
    let nextAction = (
      <button
        onClick={(e) => {
          this.rowAction("Manage", data, e);
        }}
        className={
          "dynamicTableActionButton btn btn-md d-inline-flex align-items-center"
        }
      >
        {(latest_state.toLowerCase() ===
          constants.MANAGE_CONTENT_PERMISSIONS.APPROVED.SLUG ||
          latest_state.toLowerCase() ===
            constants.MANAGE_CONTENT_PERMISSIONS.PUBLISHED.SLUG) && (
          <>
            <span
              className="material-icons-outlined text-blue-clr icon-fs-20"
              data-for={`Tooltip-${data.transaction_id}-View`}
              data-tip="View"
              data-iscapture="true"
            >
              visibility
            </span>
          </>
        )}
        {latest_state.toLowerCase() !==
          constants.MANAGE_CONTENT_PERMISSIONS.APPROVED.SLUG &&
          latest_state.toLowerCase() !==
            constants.MANAGE_CONTENT_PERMISSIONS.PUBLISHED.SLUG && (
            <>
              <span
                className="material-icons-outlined text-blue-clr icon-fs-20"
                data-for={`Tooltip-${data.transaction_id}-Manage`}
                data-tip="Manage"
                data-iscapture="true"
              >
                settings
              </span>
            </>
          )}
      </button>
    );
    if (user !== null && loggedInUserId !== null && loggedInRole !== null) {
      if (user === loggedInUserId) {
        if (
          latest_state.toLowerCase() ===
          constants.MANAGE_CONTENT_PERMISSIONS.REJECTED.SLUG
        ) {
          nextAction = (
            <button
              onClick={(e) => {
                this.rowAction("Manage", data, e);
              }}
              className={
                "dynamicTableActionButton btn btn-md d-inline-flex align-items-center"
              }
            >
              <span
                className="material-icons-outlined text-blue-clr icon-fs-20"
                data-for={`Tooltip-${data.transaction_id}-${constants.MANAGE_CONTENT_PERMISSIONS.RE_SUBMIT.BUTTON_TITLE}`}
                data-tip={
                  constants.MANAGE_CONTENT_PERMISSIONS.RE_SUBMIT.BUTTON_TITLE
                }
                data-iscapture="true"
              >
                edit
              </span>
            </button>
          );
        }
        if (
          latest_state.toLowerCase() ===
          constants.MANAGE_CONTENT_PERMISSIONS.DRAFT.SLUG
        ) {
          nextAction = (
            <button
              onClick={(e) => {
                this.rowAction("Manage", data, e);
              }}
              className={
                "dynamicTableActionButton btn btn-md d-inline-flex align-items-center"
              }
            >
              <span
                className="material-icons-outlined text-blue-clr icon-fs-20"
                data-for={`Tooltip-${data.transaction_id}-Edit`}
                data-tip="Edit"
                data-iscapture="true"
              >
                edit
              </span>
            </button>
          );
        }
      } else {
        if (
          latest_state.toLowerCase() ===
          constants.MANAGE_CONTENT_PERMISSIONS.REJECTED.SLUG
        ) {
          nextAction = (
            <button
              onClick={(e) => {
                this.rowAction("Manage", data, e);
              }}
              className={
                "dynamicTableActionButton btn btn-md d-inline-flex align-items-center"
              }
            >
              <span
                className="material-icons-outlined text-blue-clr icon-fs-20"
                data-for={`Tooltip-${data.transaction_id}-View`}
                data-tip="View"
                data-iscapture="true"
              >
                visibility
              </span>
            </button>
          );
        }
      }
      if (
        latest_state.toLowerCase() ===
        constants.MANAGE_CONTENT_PERMISSIONS.REVIEW.SLUG
      ) {
        if (
          user !== loggedInUserId &&
          ((latest_role !== null && loggedInRole === latest_role) ||
            (latest_role !== null &&
              constants.USER_ROLES.ADMIN.SLUG === loggedInRole.toLowerCase()) ||
            (latest_role === null &&
              (loggedInRole === constants.USER_ROLES.SENIOR_ANALYST.NAME ||
                loggedInRole === constants.USER_ROLES.PRACTICE_MANAGER.NAME ||
                loggedInRole === constants.USER_ROLES.ADMIN.NAME)))
        ) {
          nextAction = (
            <button
              onClick={(e) => {
                this.rowAction("Manage", data, e);
              }}
              className={
                "dynamicTableActionButton btn btn-md d-inline-flex align-items-center"
              }
            >
              <span
                className="material-icons-outlined text-blue-clr icon-fs-20"
                data-for={`Tooltip-${data.transaction_id}-${constants.MANAGE_CONTENT_PERMISSIONS.REVIEW.BUTTON_TITLE}`}
                data-tip={
                  constants.MANAGE_CONTENT_PERMISSIONS.REVIEW.BUTTON_TITLE
                }
                data-iscapture="true"
              >
                description
              </span>
            </button>
          );
        } else {
          nextAction = (
            <button
              onClick={(e) => {
                this.rowAction("Manage", data, e);
              }}
              className={
                "dynamicTableActionButton btn btn-md d-inline-flex align-items-center"
              }
            >
              {loggedInRole === constants.USER_ROLES.ADMIN.NAME ? (
                <>
                  <span
                    className="material-icons-outlined text-blue-clr icon-fs-20"
                    data-for={`Tooltip-${data.transaction_id}-${constants.MANAGE_CONTENT_PERMISSIONS.REVIEW.BUTTON_TITLE}`}
                    data-tip={
                      constants.MANAGE_CONTENT_PERMISSIONS.REVIEW.BUTTON_TITLE
                    }
                    data-iscapture="true"
                  >
                    description
                  </span>
                </>
              ) : (
                <>
                  <span
                    className="material-icons-outlined text-blue-clr icon-fs-20"
                    data-for={`Tooltip-${data.transaction_id}-View`}
                    data-tip="View"
                    data-iscapture="true"
                  >
                    visibility
                  </span>
                </>
              )}
            </button>
          );
        }
      }
    } else {
      nextAction = (
        <button
          onClick={(e) => {
            this.rowAction("Manage", data, e);
          }}
          className={
            "dynamicTableActionButton btn btn-md d-inline-flex align-items-center"
          }
        >
          {(latest_state.toLowerCase() ===
            constants.MANAGE_CONTENT_PERMISSIONS.APPROVED.SLUG ||
            latest_state.toLowerCase() ===
              constants.MANAGE_CONTENT_PERMISSIONS.PUBLISHED.SLUG) && (
            <>
              <span
                className="material-icons-outlined text-blue-clr icon-fs-20"
                data-for={`Tooltip-${data.transaction_id}-View`}
                data-tip="View"
                data-iscapture="true"
              >
                visibility
              </span>
            </>
          )}
          {latest_state.toLowerCase() !==
            constants.MANAGE_CONTENT_PERMISSIONS.APPROVED.SLUG &&
            latest_state.toLowerCase() !==
              constants.MANAGE_CONTENT_PERMISSIONS.PUBLISHED.SLUG && (
              <>
                <span
                  className="material-icons-outlined text-blue-clr icon-fs-20"
                  data-for={`Tooltip-${data.transaction_id}-Manage`}
                  data-tip="Manage"
                  data-iscapture="true"
                >
                  settings
                </span>
              </>
            )}
        </button>
      );
    }
    return nextAction;
  };
  getTableData = () => {
    let queryParams = "";
    if (this.state.sortOrder) {
      queryParams =
        "&sort_order=" +
        this.state.sortOrder +
        "&sort_column=" +
        this.state.sortColumn;
    }
    if (this.state.name !== "") {
      queryParams +=
        "&search_column=eco_system_name&search_value=" + this.state.name;
    }
    if (this.props.type) {
      queryParams += "&state=" + this.props.type;
    }
    if (this.props.role && this.props.role !== null) {
      queryParams += "&role=" + this.props.role;
    }
    if (this.props.selectedTab && this.props.selectedTab !== null) {
      queryParams += "&selected_tab=" + this.props.selectedTab;
    }
    this.props.history.push({
      pathname: this.props.location.pathname,
      search:
        "?page=" +
        this.state.pageNo +
        "&page_size=" +
        this.state.pageSize +
        queryParams,
    });
    ManageContentServices.getManageContentTransactions(
      "transactions?type=ecosystem&page=" +
        this.state.pageNo +
        "&page_size=" +
        this.state.pageSize +
        queryParams
    )
      .then((res) => {
        let companyData = res.data.data;
        let count =
          (res.data && res.data.meta && res.data.meta.total_count) || 0;
        companyData = companyData.map((data) => {
          let current_status = `${
            data.current_role_name === null
              ? "Ecosystem Reviewer"
              : data.current_role_name
          } (${data.current_state_name})`;
          if (
            ((data.current_role_name === "Editor" ||
              data.current_role_name === "Admin") &&
              data.current_state_name === "Approved") ||
            data.current_state_name === "Published"
          )
            current_status = "Published";
          let ecoSysDraftName = CustomEllipsis(data.eco_system_name);
          return {
            id: data.id,
            transaction_id: data.transaction_id,
            eco_system_name: (
              <label className={`mb-0`}>{data.eco_system_name}</label>
            ),
            categories: (
              <>
                <span
                  key={data.id}
                  className={"datagrid_tooltip"}
                  id={`tooltip-${data.id}`}
                  data-for={`tooltip-${data.id}`}
                  data-tip={this.extractCategories(
                    data.categories ? data.categories : {}
                  )}
                  data-iscapture="true"
                >
                  {/* <i className={'fa fa-info-circle'}></i> */}
                  <span className="material-icons-outlined icon">info</span>
                </span>
                <CustomReactTooltip
                  id={`tooltip-${data.id}`}
                  multiline={true}
                />
              </>
            ),
            status: current_status,
            created_by: data.created_by_name,
            created_at: data.created_at
              ? ComapnyDetailsDateTimeFormatter(data.created_at, false)
              : ComapnyDetailsDateTimeFormatter(new Date(), false),
            updated_at: data.updated_at
              ? ComapnyDetailsDateTimeFormatter(data.updated_at, false)
              : "-",
            actions: this.getNextAction(
              data.created_by ? data.created_by : null,
              data.current_role_name,
              data.current_state_name,
              data
            ),
          };
        });
        this.setState({ rowData: companyData, count: count });

        // Below loader is added on purpose -> to not to make the user switch tabs until UI is displayed.
        // which in turn reduces the load on DB (might delete later.)
        document.body.classList.add("loading-indicator");
        setTimeout(() => {
          document.body.classList.remove("loading-indicator");
        }, 1000);
      })
      .catch((error) => {
        this.setState({ rowData: [], count: 0 });
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  handlePageSize = (e) => {
    this.setState({ pageSize: e.target.value, pageNo: 1 }, () => {
      this.getTableData();
    });
  };
  handleInput = (e) => {
    this.setState({ name: e.target.value });
  };
  rowAction = (action, data, e) => {
    e.stopPropagation();
    switch (action) {
      case constants.MANAGE_CONTENT_MY_DRAFTS.LIST.VIEW_DETAILS:
        this.props.history.push({
          pathname: formatRoute(
            constants.APPLICATION_ROUTE
              .MANAGE_CONTENT_ECOSYSTEM_TRANSACTION_DETAILS.ROUTE,
            { id: data.id }
          ),
        });
        break;
      case "Manage":
        this.props.history.push({
          pathname: formatRoute(
            constants.APPLICATION_ROUTE
              .MANAGE_CONTENT_ECOSYSTEM_TRANSACTION_DETAILS.ROUTE,
            { id: data.transaction_id }
          ),
        });
        break;
      default:
        break;
    }
  };
  render() {
    return (
      <div className={""}>
        <div className="d-flex align-items-center mb-10px">
          <div className="min-width-266 border-search search-box-shadow-2">
            <Search
              handleSearch={this.handleSearch}
              handleInput={this.handleInput}
              value={this.state.name}
              placeHolder={"Filter Ecosystem Name"}
            />
          </div>
        </div>
        <div className="custom-table-block custom-table-block-2 content-trasactions-table-block first-col-fixed-block nowrap-table-block th-nowrap sort-table-block center-th-align">
          <DynamicTableComponentNew
            columnData={tableHeader}
            rowData={this.state.rowData}
            pagePrevious={this.pagePrevious}
            pageNext={this.pageNext}
            pageSize={this.state.pageSize}
            pageNo={this.state.pageNo}
            handlePage={this.handlePage}
            handleSort={this.handleSort}
            sortColumn={this.state.sortColumn}
            sortOrder={this.state.sortOrder}
            count={this.state.count}
            rowAction={this.rowAction}
            colSpan={tableHeader.filter((h) => h.visibility).length}
            showActions={showActions}
            showPagination={true}
            paginationClass={"center-pagination custom-pagination-new"}
            handlePageSize={this.handlePageSize}
          />
        </div>

        {/* Tooltips for icons in table, don't delete */}
        {this.state.rowData.map((data) => {
          return (
            <>
              <CustomReactTooltip
                id={`Tooltip-${data.transaction_id}-View`}
                multiline={true}
              />
              <CustomReactTooltip
                id={`Tooltip-${data.transaction_id}-Manage`}
                multiline={true}
              />
              <CustomReactTooltip
                id={`Tooltip-${data.transaction_id}-${constants.MANAGE_CONTENT_PERMISSIONS.RE_SUBMIT.BUTTON_TITLE}`}
                multiline={true}
              />
              <CustomReactTooltip
                id={`Tooltip-${data.transaction_id}-Edit`}
                multiline={true}
              />
              <CustomReactTooltip
                id={`Tooltip-${data.transaction_id}-${constants.MANAGE_CONTENT_PERMISSIONS.REVIEW.BUTTON_TITLE}`}
                multiline={true}
              />
            </>
          );
        })}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    home: state.home,
  };
};

export default connect(
  mapStateToProps,
  null
)(ListEcosystemManageContentTransaction);
