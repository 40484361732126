import axios from "axios";
import { formatRoute } from "react-router-named-routes";
import constants from "../Utils/constants";
import Toast from "../Utils/Toast";
import { msalInstance } from "../Auth/MSAL-Instance";
import clearLocalStorage from "../Utils/clearLocalStorage";
import decodeJWT from "./decodeJWT";

let baseURL, timeout;
baseURL = process.env.REACT_APP_TBRI_API_URL;
timeout = process.env.REACT_APP_AXIOS_TIMEOUT
  ? parseInt(process.env.REACT_APP_AXIOS_TIMEOUT)
  : 60000;

let Client_redirect_url;
Client_redirect_url = process.env.REACT_APP_CLIENT_REDIRECT_URL;

let Axios;
let is_refreshing = false;
let refreshSubscribers = [];

const removeLoader = [
  "metric_template/upload",
  "metric_template/addTransactions",
];

const init = () => {
  setItem(0); // intialise api count to 0
  Axios = axios.create({
    baseURL: baseURL,
    timeout: timeout,
  });
  Axios.interceptors.request.use(handleSuccessRequest, handleErrorRequest);
  Axios.interceptors.response.use(handleSuccess, handleError);
};

const handleSuccessRequest = async (request) => {
  if (!removeLoader.includes(request.url)) {
    addLoadingIndicator();
  }

  if (
    localStorage.getItem("token") != null &&
    decodeJWT(localStorage.getItem("token")).user.is_admin !== 1
  ) {
    removeLoadingIndicator();
    localStorage.removeItem("token");
    localStorage.removeItem("is_admin");
    localStorage.removeItem("refresh_token");
    refreshSubscribers = [];
    window.location.href = formatRoute(Client_redirect_url, {});
    removeLoadingIndicator();
    return Promise.reject("You are not allowed");
  }
  if (localStorage.getItem("SSO") === "true") {
    let token = await msalInstance.get_SSO_ID_Token();
    // let token = `eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6Ik1yNS1BVWliZkJpaTdOZDFqQmViYXhib1hXMCJ9.eyJhdWQiOiJlMWNlYWJmYi0yMmUxLTQ1Y2UtYmZhMi0wYWQ2ZWRjNTkyMmUiLCJpc3MiOiJodHRwczovL2xvZ2luLm1pY3Jvc29mdG9ubGluZS5jb20vNzg5NmM1YWEtNzFiNC00N2MzLTk4ZDQtZTcwN2JkZDc0NjJmL3YyLjAiLCJpYXQiOjE2NDM3ODM1OTcsIm5iZiI6MTY0Mzc4MzU5NywiZXhwIjoxNjQzNzg3NDk3LCJhaW8iOiJBVlFBcS84VEFBQUFpRFJua3BadUJJb0R1ZVBXUE9uMVl5ZEdaTjQybDdyTnh0bk5WVEFKQ3JTbml3Q2F4TVFCaHFudHFibmZHOWw4aXNmQjdXODI2S3g4WWVpcHlNdFExU3FZQjlCeTUvdFFjS280SHNwZlZ5RT0iLCJlbWFpbCI6ImFraGlsLmthcmFua290aUBhY3NpY29ycC5jb20iLCJuYW1lIjoiS2FyYW5rb3RpLCBBa2hpbCIsIm5vbmNlIjoiMjI5NDgxYTgtZDg1Yi00NTA3LTkyZjgtYzI3ODUwNmIzMGVmIiwib2lkIjoiZDhjMWNiMzktMzRiNi00MTBiLWJkNGQtZmIyZjljY2ZiYWM3IiwicHJlZmVycmVkX3VzZXJuYW1lIjoiYWtoaWwua2FyYW5rb3RpQGFjc2ljb3JwLmNvbSIsInJoIjoiMC5BUTBBcXNXV2VMUnh3MGVZMU9jSHZkZEdMX3VyenVIaElzNUZ2NklLMXUzRmtpNE5BTDguIiwic3ViIjoic1F0c2dCcUlfTEJvRFoyc2hOQkFNLWZieGhjTWdHa1dMdVVDdW9jUTBKVSIsInRpZCI6Ijc4OTZjNWFhLTcxYjQtNDdjMy05OGQ0LWU3MDdiZGQ3NDYyZiIsInV0aSI6ImtoQUY0ZXJCakUydTZqb3NIYjBVQUEiLCJ2ZXIiOiIyLjAifQ.aWUUJeQzben2zP4kQkv02DSsRW4buaxpSBwtNlNQvpApiaGkcL-ODAp87fQnL259wCLuB_-dbFo7vvTwEyVIYS5RicdwbIhf6j7zCXpXCyRpOIszSoEZVPwBV91TfH5ZU73StyxXbP9NmDUWRKcPv2ilzCAjc4aA97fY8s_6b_2B58biFrGhAF_kBw1mTmU7Ty6RmtLzGQ2wpEohh7tjzl9YqggZ4jeT82F1YnwDTnLsryRE6zHIACh0GlLzkb0KNHDNFmyARrLBfUjLS456giwoFjQ64JUx6prKkVhie_ciYCD2P1ZWGoXfqagXCgkUxC1Qv0srcMF-WhUtQL4MnA`;
    request.headers["X-IDENTITY"] = `${token}`;
  }

  if (localStorage.getItem("token")) {
    request.headers["Authorization"] = `Bearer ${localStorage.getItem(
      "token"
    )}`;
  }
  return request;
};

const handleErrorRequest = (error) => {
  // document.body.classList.remove('loading-indicator')
  removeLoadingIndicator();
  return Promise.reject(error);
};

const handleSuccess = (response) => {
  // document.body.classList.remove('loading-indicator');
  removeLoadingIndicator();
  return response;
};

const handleError = async (error) => {
  removeLoadingIndicator();
  if (error.message === "Network Error") {
    // The user doesn't have internet
    return Promise.reject(error.response);
  }
  try {
    switch (error.response.status) {
      case 400:
        let error_msg =
          (error &&
            error.response &&
            error.response.data &&
            error.response.data.error) ||
          "";
        // if error is sso id token is invalid, retry request so that it will get sso id token.
        if (error_msg === constants.TOKEN.INVALID_SSO_ID_TOKEN) {
          error.response.config.__isRetryRequest = true;
          return Axios.request(error.response.config);
        }

        if (error_msg === constants.TOKEN.INVALID_MESSAGE) {
          clearLocalStorage();
          window.location.href = formatRoute(
            constants.APPLICATION_ROUTE.LOGIN.ROUTE,
            {}
          );
        }
        break;
      case 401:
        //Un authorized
        if (localStorage.getItem("refresh_token")) {
          // document.body.classList.add('loading-indicator')
          // addLoadingIndicator();
          // let sso_idToken;
          // if (localStorage.getItem("SSO") === "true") {
          //   sso_idToken = await msalInstance.get_SSO_ID_Token();
          // }
          // return axios.post(baseURL + 'token', { "refresh_token": localStorage.getItem('refresh_token') }, { headers: { 'X-IDENTITY': `${sso_idToken}` } })
          //   .then(responseNew => {
          //     localStorage.setItem('token', responseNew.data.token.access_token)
          //     localStorage.setItem('refresh_token', responseNew.data.token.refresh_token)
          //     error.response.config.__isRetryRequest = true
          //     error.response.config.headers.Authorization = 'Bearer ' + responseNew.data.token.access_token
          //     // document.body.classList.remove('loading-indicator')
          //     removeLoadingIndicator();
          //     return axios(error.response.config)
          //   })
          //   .catch(res => {
          //     // document.body.classList.remove('loading-indicator')
          //     removeLoadingIndicator();
          //     // localStorage.removeItem('token')
          //     // localStorage.removeItem('refresh_token')
          //     // localStorage.removeItem("SSO");
          //     clearLocalStorage();
          //     window.location.href = formatRoute(Login_redirect_url, {})
          //   })
          return refreshAccessToken(error);
        }
        break;
      case 404:
        // Show 404 page
        break;
      case 422:
        Toast(error.response.data.error, "error");
      case 500:
        // Serveur Error redirect to 500
        break;
      default:
        Promise.reject(error.response);
        break;
    }
  } catch (e) {}
  return Promise.reject(error.response);
};

const addLoadingIndicator = () => {
  let apiCount = getItem();
  apiCount = apiCount ? apiCount + 1 : 1;
  setItem(apiCount);
  document.body.classList.add("loading-indicator");
};

const removeLoadingIndicator = () => {
  let apiCount = getItem();
  apiCount = apiCount ? apiCount - 1 : 0;
  setItem(apiCount);
  if (!apiCount) {
    document.body.classList.remove("loading-indicator");
  }
};

const getItem = () => {
  return parseInt(sessionStorage.getItem("apiCount"));
};

const setItem = (apiCount) => {
  return sessionStorage.setItem("apiCount", apiCount);
};

init();

const onRrefreshed = (token) => {
  refreshSubscribers.forEach((cb) => cb(token));
  refreshSubscribers = [];
};

const subscribeTokenRefresh = (cb) => {
  refreshSubscribers.push(cb);
};

/**
 * For ref go through https://gist.github.com/mkjiau/650013a99c341c9f23ca00ccb213db1c
 * @param {Request Object} originalRequest
 * @returns Promise
 */
const refreshAccessToken = async (originalRequest) => {
  if (!is_refreshing) {
    addLoadingIndicator();
    let sso_idToken;
    if (localStorage.getItem("SSO") === "true") {
      sso_idToken = await msalInstance.get_SSO_ID_Token();
    }
    is_refreshing = true;
    axios
      .post(
        baseURL + "token",
        { refresh_token: localStorage.getItem("refresh_token") },
        { headers: { "X-IDENTITY": `${sso_idToken}` } }
      )
      .then((responseNew) => {
        localStorage.setItem("token", responseNew.data.token.access_token);
        localStorage.setItem(
          "refresh_token",
          responseNew.data.token.refresh_token
        );
        removeLoadingIndicator();
        is_refreshing = false;
        onRrefreshed(responseNew.data.token.access_token);
      })
      .catch((res) => {
        removeLoadingIndicator();
        localStorage.removeItem("token");
        localStorage.removeItem("refresh_token");
        refreshSubscribers = [];
        clearLocalStorage();
        window.location.href = window.location.href;
      });
  }
  return new Promise((resolve, reject) => {
    subscribeTokenRefresh((token, err) => {
      if (err) {
        reject(err);
      } else {
        originalRequest.response.config.__isRetryRequest = true;
        originalRequest.response.config.headers.Authorization =
          "Bearer " + token;
        addLoadingIndicator();
        resolve(
          axios(originalRequest.response.config)
            .then((res) => res)
            .catch((err) => err)
            .finally(() => removeLoadingIndicator())
        );
      }
    });
  });
};

export { Axios, baseURL, init };
