import React, { Component } from "react";
import { connect } from "react-redux";
import { formatRoute } from "react-router-named-routes";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import CustomDateTimePicker from "../Components/CustomDateTimePicker";
import CustomDropdownList from "../Components/CustomDropdownList";
import DailogNew from "../Components/DailogNew";
import ScrollToTopBtn from "../Components/ScrollToTop";
import TemplateParser from "../Components/TemplateParser";
import permissionCheck from "../lib/permissionCheck";
import { categories, companies } from "../Store/Actions/CompanyDetails";
import constants from "../Utils/constants";
import DocumentTitle from "../Utils/DocumentTitle";
import Toast from "../Utils/Toast";
import Collapsible from "react-collapsible";
import CustomTitle from "../Components/CustomTitle";

class CompanyDetailsContent extends Component {
  constructor(props) {
    super(props);
    this.divRef = React.createRef();
    this.showMoreRef = React.createRef();
    this.closeRef = React.createRef();
  }

  state = {
    titleModal: false,
    uid: null,
    placeholder: null,
    originalTitleValue: null,
    modifiedTitleValue: null,
    dailogModal: false,
    dailogModalContent: "",
    dailogModalHeader: "",
    dailogModalConfig: {},
    dailogModalStyleType: "",
    addAuthorModal: false,
    popoverOpen: false,
    showMore: false,
  };

  componentDidUpdate() {
    this.handleOverFlow();
  }
  handleShowMore = () => {
    this.setState({ showMore: !this.state.showMore });
  };

  componentDidMount() {
    this.tempToggleModal(true);
    setTimeout(() => {
      this.tempToggleModal(false);
    }, 200);
  }

  // method for to re-render the content
  tempToggleModal = (param) => {
    this.setState({
      titleModal: param,
    });
  };

  handleOverFlow = () => {
    let element = this.divRef;
    if (element.current && this.showMoreRef.current) {
      if (
        element.current.offsetHeight < element.current.scrollHeight &&
        !this.state.showMore
      ) {
        this.showMoreRef.current.classList.remove("d-none");
      } else {
        this.showMoreRef.current.classList.add("d-none");
      }
    }
  };

  toggleTitleModal = (originalTitleValue, uid) => {
    this.setState({
      titleModal: !this.state.titleModal,
      originalTitleValue: originalTitleValue,
      modifiedTitleValue: originalTitleValue,
      uid: uid,
    });
  };

  closeTitleModal = () => {
    this.setState({
      titleModal: false,
    });
  };

  saveTitleModal = () => {
    if (this.state.modifiedTitleValue.length > 300) {
      Toast(constants.TITLE_LIMIT_300, "error");
    } else {
      this.setState(
        {
          originalTitleValue: this.state.modifiedTitleValue,
        },
        () => {
          this.props.onSectionTitleChangeHandler(
            this.state.uid,
            this.state.originalTitleValue
          );
          this.closeTitleModal();
        }
      );
    }
  };

  toggleAddAuthorsModal = () => {
    this.setState({
      addAuthorModal: !this.state.addAuthorModal,
    });
  };

  closeAuthorModal = () => {
    this.setState({
      addAuthorModal: false,
    });
  };

  saveAddAuthorsModal = () => {
    Toast(
      constants.MANAGE_CONTENT_TRANSACTION_DETAILS.ADD_AUTHOR_SUCCESS_MESSAGE,
      "success"
    );
    this.setState(
      {
        addAuthorModal: false,
      },
      () => {
        this.props.addAuthor();
      }
    );
  };

  onTitleChangeHandler = (e) => {
    this.setState({
      modifiedTitleValue: e.target.value,
    });
  };

  confirmDailog = (uid) => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">Delete</h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="remove-body">
          <h2 className="font-weight-semibold mb-0">
            {
              constants.MANAGE_CONTENT_TRANSACTION_DETAILS
                .DELETE_CONTENT_CONFIRM_MESSAGE
            }
          </h2>
        </div>
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: { type: "section", uid: uid },
      dailogModalStyleType: "danger-modal",
    });
  };

  removeAuthorConfirmation = (author) => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">Remove</h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="remove-body">
          <h2 className="font-weight-semibold mb-0">
            {
              constants.MANAGE_CONTENT_TRANSACTION_DETAILS
                .DELETE_AUTHOR_CONFIRM_MESSAGE
            }
          </h2>
        </div>
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: {
        type: "remove_author",
        uid: author.company_segment_author.id,
      },
      dailogModalStyleType: "danger-modal",
    });
  };

  confirmDailogUndo = (uid) => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">Undo</h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="inactive-body">
          <h2 className="font-weight-semibold mb-0">
            {
              constants.MANAGE_CONTENT_TRANSACTION_DETAILS
                .UNDO_DELETE_ACTION_CONFIRM_MESSAGE
            }
          </h2>
        </div>
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: { type: "section_recover", uid: uid },
      dailogModalStyleType: "inactive-modal",
    });
  };

  dailogModalAccept = (config) => {
    if (config.type === "section") {
      this.props.removeContent(config.uid);
      this.setState({
        dailogModal: false,
        dailogModalHeader: "",
        dailogModalContent: "",
        dailogModalConfig: {},
        dailogModalStyleType: "",
      });
    } else if (config.type === "section_recover") {
      this.props.undoRemoveContent(config.uid);
      this.setState({
        dailogModal: false,
        dailogModalHeader: "",
        dailogModalContent: "",
        dailogModalConfig: {},
        dailogModalStyleType: "",
      });
    } else if (config.type === "remove_author") {
      this.props.removeAuthor(config.uid);
      this.setState({
        dailogModal: false,
        dailogModalHeader: "",
        dailogModalContent: "",
        dailogModalConfig: {},
        dailogModalStyleType: "",
      });
      Toast(
        constants.MANAGE_CONTENT_TRANSACTION_DETAILS
          .DELETE_AUTHOR_SUCCESS_MESSAGE,
        "success"
      );
    }
  };

  dailogModalDecline = () => {
    this.setState({
      dailogModal: false,
      dailogModalContent: "",
      dailogModalConfig: {},
    });
  };

  //updating the published date
  updated_published_date = (content, subcategory_id) => {
    let subcategorycontents = content.filter(
      (segment) =>
        segment.subcategory_id === subcategory_id && !segment.is_deleted
    );
    return subcategorycontents[subcategorycontents.length - 1].published_date;
  };

  render() {
    let companyContent =
      this.props.company_details && this.props.company_details.companyContent
        ? this.props.company_details.companyContent
        : [];
    let categories =
      this.props.company_details && this.props.company_details.categories
        ? this.props.company_details.categories
        : [];
    let companies =
      this.props.company_details && this.props.company_details.companies
        ? this.props.company_details.companies
        : [];
    let segments =
      companies.filter((comp) => comp.is_selected === true).length > 0
        ? companies.filter((comp) => comp.is_selected === true)[0].segments
        : [];
    let companyDetails =
      this.props.company_details && this.props.company_details.companyDetails
        ? this.props.company_details.companyDetails
        : {};
    let selectedSegment = segments.find((s) => s.is_selected === true);
    let authors = [];
    let tbriUsers =
      this.props.home && this.props.home.getTbriUsers
        ? this.props.home.getTbriUsers
        : [];
    if (selectedSegment) {
      let a = companyDetails.segments
        ? companyDetails.segments.find((s) => s.id === selectedSegment.id)
        : [];
      authors = a && a.authors ? a.authors : [];
    }
    let authorIds = authors.map((a) => a.id);
    tbriUsers = tbriUsers.filter((t) => !authorIds.includes(t.id));
    let breadcrumbs = [
      {
        title: constants.APPLICATION_ROUTE.DASHBOARD.NAME,
        link: formatRoute(constants.APPLICATION_ROUTE.DASHBOARD.ROUTE, {}),
        is_active: false,
      },
      {
        title: constants.APPLICATION_ROUTE.COMPANY_GROUP.NAME,
        link: null,
        is_active: false,
      },
      {
        title: constants.APPLICATION_ROUTE.COMPANY.LIST.NAME,
        link: formatRoute(constants.APPLICATION_ROUTE.COMPANY.LIST.ROUTE, {}),
        is_active: false,
      },
      {
        title: `${companyDetails.name ? companyDetails.name : ""}`,
        link: this.props.location,
        is_active: true,
      },
    ];
    let hrefLink = "#";
    DocumentTitle(
      `${companyDetails.name ? companyDetails.name : ""} - ${
        selectedSegment && selectedSegment.id ? selectedSegment.name : ""
      }`
    );
    return (
      <aside className="dash_board_content inner-sidebar-right-content">
        {companies && companies.length > 0 && (
          <>
            <ScrollToTopBtn />

            {segments.length > 0 && (
              <>
                <div className="segments-card-wrapper btn-items-card-wrapper">
                  <div
                    ref={this.divRef}
                    className={
                      this.state.showMore
                        ? "card custom-card segments-card btn-items-card open-card"
                        : "card custom-card segments-card btn-items-card closed-card"
                    }
                  >
                    <div className="card-body">
                      <div className="d-flex">
                        <h3 className="font-weight-semibold mb-0 text-black2 title">
                          Segments
                        </h3>
                        <ul className="segment-items btn-items d-flex flex-wrap">
                          {segments
                            .sort((a, b) =>
                              a.name > b.name ? 1 : b.name > a.name ? -1 : 0
                            )
                            .map((segment, index) => (
                              <li
                                key={segment.id}
                                className={
                                  segment.is_selected === true ? "active" : ""
                                }
                              >
                                <div
                                  className={`content font-weight-semibold d-flex align-items-center`}
                                  onClick={() => {
                                    this.props.switchSegmentHandler(segment.id);
                                  }}
                                >
                                  {segment.name}
                                </div>
                              </li>
                            ))}
                        </ul>

                        <div className="align-self-start d-flex mt-1 show-more-btn ml-auto">
                          <button
                            ref={this.showMoreRef}
                            type="button"
                            className="btn btn-link font-weight-semibold d-none"
                            onClick={this.handleShowMore}
                          >
                            show more
                          </button>
                          {this.state.showMore ? (
                            <span
                              className="material-icons-round close-icon"
                              onClick={this.handleShowMore}
                            >
                              close
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="d-flex align-items-center">
              {this.props.isEditable && (
                <div className="ml-auto">
                  <div className={"text-right"}>
                    {segments.length > 0 && (
                      <button
                        className={
                          "btn btn-custom btn-danger icon-text-btn title-btn d-inline-flex align-items-center my-3"
                        }
                        type="button"
                        onClick={this.props.resetContent}
                        disabled={!this.props.changesMade}
                      >
                        {" Reset"}
                        <span className="material-icons-outlined icon-fs-16 ml-1">
                          restart_alt
                        </span>
                      </button>
                    )}
                    {permissionCheck(
                      constants.MANAGE_CONTENT_PERMISSIONS.KEY,
                      constants.MANAGE_CONTENT_PERMISSIONS.ENTITY,
                      constants.MANAGE_CONTENT_PERMISSIONS.DRAFT.SLUG
                    ) &&
                      segments.length > 0 && (
                        <button
                          className={
                            "btn btn-custom btn-success icon-text-btn title-btn d-inline-flex align-items-center my-3 mx-3"
                          }
                          type="button"
                          onClick={() => {
                            this.props.saveContent(
                              constants.MANAGE_CONTENT_PERMISSIONS.DRAFT.SLUG
                            );
                          }}
                          disabled={!this.props.changesMade}
                        >
                          {" " +
                            constants.MANAGE_CONTENT_PERMISSIONS.DRAFT
                              .BUTTON_TITLE}
                          <span className="material-icons-outlined icon-fs-16 ml-1">
                            save
                          </span>
                        </button>
                      )}
                    {permissionCheck(
                      constants.MANAGE_CONTENT_PERMISSIONS.KEY,
                      constants.MANAGE_CONTENT_PERMISSIONS.ENTITY,
                      constants.MANAGE_CONTENT_PERMISSIONS.SUBMIT.SLUG
                    ) &&
                      segments.length > 0 && (
                        <button
                          className={
                            "btn btn-custom btn-primary icon-text-btn title-btn d-inline-flex align-items-center my-3"
                          }
                          type="button"
                          onClick={() => {
                            this.props.saveContent(
                              constants.MANAGE_CONTENT_PERMISSIONS.SUBMIT.SLUG
                            );
                          }}
                          disabled={!this.props.changesMade}
                        >
                          {" " +
                            constants.MANAGE_CONTENT_PERMISSIONS.SUBMIT
                              .BUTTON_TITLE}
                          <span className="material-icons-outlined icon-fs-16 ml-1">
                            done_all
                          </span>
                        </button>
                      )}
                  </div>
                </div>
              )}
            </div>

            <div className="item_details_scroll">
              {categories.map((category, index) =>
                companyContent &&
                companyContent.length > 0 &&
                companyContent.filter((segment) =>
                  category.subcategories.find(
                    (value) => value.id === segment.subcategory_id
                  )
                ).length > 0 ? (
                  <div className="custom-collapser custom-collapser2 company-details-collapser details-page-collapser">
                    <div className="category-wrapper">
                      <Collapsible
                        open={true}
                        trigger={
                          <div className="collapse-trigger">
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="d-flex align-items-center">
                                <CustomTitle
                                  title={category.name}
                                  title_html={category.name_html}
                                  tag="h3"
                                  className="font-weight-bold mb-0 title category_name"
                                />
                              </div>
                              <div className="d-flex align-items-center">
                                <div className="collapse-icon-wrapper">
                                  <span className="material-icons-round add icon">
                                    add
                                  </span>
                                  <span className="material-icons-round remove icon">
                                    remove
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        }
                      >
                        {category.subcategories.map(
                          (sub_category, sub_index) =>
                            companyContent &&
                            companyContent.length > 0 &&
                            companyContent.filter(
                              (segment) =>
                                segment.subcategory_id === sub_category.id
                            ).length > 0 && (
                              <div className={`sub-category-section`}>
                                <div
                                  className="tbr_position inner-content-section"
                                  key={sub_index}
                                >
                                  <div className="">
                                    <div className="sub_category_heading  d-flex align-items-center">
                                      <CustomTitle
                                        title={sub_category.name}
                                        title_html={sub_category.name_html}
                                        tag="h3"
                                        className="heading mb-0 sub_category_heading_title ellipsis"
                                      />
                                      {companyContent.filter(
                                        (segment) =>
                                          segment.subcategory_id ===
                                            sub_category.id &&
                                          !segment.is_deleted
                                      )[0] && (
                                        <>
                                          {companyContent.filter(
                                            (segment) =>
                                              segment.subcategory_id ===
                                                sub_category.id &&
                                              !segment.is_deleted
                                          )[0].published_date && (
                                            <>
                                              <div className="d-flex align-items-center">
                                                <h5 className="font-weight-semibold text-black2 mb-0">
                                                  Published
                                                </h5>
                                                <div className="custom-date-time-picker ml-10px">
                                                  <CustomDateTimePicker
                                                    isDisabled={
                                                      !this.props.isEditable
                                                    }
                                                    id={
                                                      companyContent.filter(
                                                        (segment) =>
                                                          segment.subcategory_id ===
                                                            sub_category.id &&
                                                          !segment.is_deleted
                                                      )[0].uid
                                                    }
                                                    date={
                                                      companyContent.filter(
                                                        (segment) =>
                                                          segment.subcategory_id ===
                                                            sub_category.id &&
                                                          !segment.is_deleted
                                                      )[0].published_date
                                                    }
                                                    onChangeHandler={
                                                      this.props
                                                        .dateOnChangeHandler
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            </>
                                          )}
                                        </>
                                      )}
                                      {/* {sub_category.mode === "news" && ( */}
                                      {/*   <> */}
                                      {/*     {!companyContent.filter( */}
                                      {/*       (segment) => */}
                                      {/*         segment.subcategory_id === */}
                                      {/*         sub_category.id */}
                                      {/*     )[0].is_deleted && */}
                                      {/*       this.props.isEditable && ( */}
                                      {/*         <div */}
                                      {/*           className="del-undo-icon-wrapper d-inline-flex" */}
                                      {/*           onClick={() => { */}
                                      {/*             this.confirmDailog( */}
                                      {/*               companyContent.filter( */}
                                      {/*                 (segment) => */}
                                      {/*                   segment.subcategory_id === */}
                                      {/*                   sub_category.id */}
                                      {/*               )[0].uid */}
                                      {/*             ); */}
                                      {/*           }} */}
                                      {/*         > */}
                                      {/*           <span className="material-icons-outlined icon icon-fs-17"> */}
                                      {/*             delete_outline */}
                                      {/*           </span> */}
                                      {/*         </div> */}
                                      {/*       )} */}
                                      {/*     {companyContent.filter( */}
                                      {/*       (segment) => */}
                                      {/*         segment.subcategory_id === */}
                                      {/*         sub_category.id */}
                                      {/*     )[0].is_deleted && ( */}
                                      {/*       <div */}
                                      {/*         className="del-undo-icon-wrapper d-inline-flex" */}
                                      {/*         onClick={() => { */}
                                      {/*           this.confirmDailogUndo( */}
                                      {/*             companyContent.filter( */}
                                      {/*               (segment) => */}
                                      {/*                 segment.subcategory_id === */}
                                      {/*                 sub_category.id */}
                                      {/*             )[0].uid */}
                                      {/*           ); */}
                                      {/*         }} */}
                                      {/*       > */}
                                      {/*         <span className="material-icons-outlined icon icon-fs-17"> */}
                                      {/*           undo */}
                                      {/*         </span> */}
                                      {/*       </div> */}
                                      {/*     )} */}
                                      {/*   </> */}
                                      {/* )} */}
                                    </div>
                                  </div>
                                  {companyContent
                                    .filter(
                                      (segment) =>
                                        segment.subcategory_id ===
                                        sub_category.id
                                    )
                                    .map((sub_category1, sub_index1) => (
                                      <div
                                        key={sub_category1.uid}
                                        className="content-section"
                                      >
                                        {
                                          <div
                                            className="title d-flex align-items-center justify-content-between"
                                            key={sub_category1.uid}
                                          >
                                            <div
                                              className={`${
                                                sub_category1.is_deleted
                                                  ? "content_deleted"
                                                  : ""
                                              } text-primary mb-0 d-inline-flex align-items-center`}
                                            >
                                              {!sub_category1.is_deleted &&
                                                this.props.isEditable && (
                                                  <>
                                                    <span
                                                      className="material-icons manage_content_action icon-fs-16 mr-1"
                                                      onClick={() => {
                                                        this.toggleTitleModal(
                                                          sub_category1.subcategory_title,
                                                          sub_category1.uid
                                                        );
                                                      }}
                                                    >
                                                      edit
                                                    </span>
                                                    &nbsp;
                                                  </>
                                                )}
                                              <CustomTitle
                                                title={
                                                  sub_category1.subcategory_title
                                                    ? sub_category1.subcategory_title
                                                    : sub_category.mode !==
                                                      "news"
                                                    ? constants.COMPANY_DETAILS
                                                        .TITLE_PLACE_HOLDER
                                                    : constants.COMPANY_DETAILS
                                                        .HEADLINE_PLACE_HOLDER
                                                }
                                                title_html={
                                                  sub_category1.subcategory_title_html
                                                }
                                                tag="h5"
                                                className={`${
                                                  sub_category1.is_deleted
                                                    ? "content_deleted"
                                                    : ""
                                                } text-primary mb-0 d-inline-flex align-items-center`}
                                              />
                                            </div>
                                            {this.props.isEditable && (
                                              <>
                                                {!sub_category1.is_deleted && (
                                                  <div
                                                    className="del-undo-icon-wrapper d-inline-flex"
                                                    onClick={() => {
                                                      this.confirmDailog(
                                                        sub_category1.uid
                                                      );
                                                    }}
                                                  >
                                                    <span className="material-icons-outlined icon icon-fs-17">
                                                      delete_outline
                                                    </span>
                                                  </div>
                                                )}
                                                {sub_category1.is_deleted && (
                                                  <div
                                                    className="del-undo-icon-wrapper d-inline-flex"
                                                    onClick={() => {
                                                      this.confirmDailogUndo(
                                                        sub_category1.uid
                                                      );
                                                    }}
                                                  >
                                                    <span className="material-icons-outlined icon icon-fs-17">
                                                      undo
                                                    </span>
                                                  </div>
                                                )}
                                              </>
                                            )}
                                          </div>
                                        }
                                        <div className="content_section template-content-section">
                                          <TemplateParser
                                            emptyContentMessage={
                                              sub_category.mode !== "news"
                                                ? constants.COMPANY_DETAILS
                                                    .CONTENT_PLACE_HOLDER
                                                : constants.COMPANY_DETAILS
                                                    .NEWS_PLACE_HOLDER
                                            }
                                            isEditable={this.props.isEditable}
                                            isDeleted={sub_category1.is_deleted}
                                            key={`temp-${sub_category1.uid}`}
                                            template={
                                              sub_category1.template.content
                                            }
                                            content={sub_category1.data}
                                            uid={sub_category1.uid}
                                            onContentChangeHandler={
                                              this.props.onContentChangeHandler
                                            }
                                            tempToggleModal={
                                              this.tempToggleModal
                                            }
                                          />
                                        </div>
                                        {sub_index1 + 1 ===
                                          companyContent.filter(
                                            (segment) =>
                                              segment.subcategory_id ===
                                              sub_category.id
                                          ).length &&
                                          this.props.isEditable && (
                                            <div>
                                              <div
                                                className="add-section-btn d-inline-flex align-items-center cursor-pointer"
                                                onClick={() => {
                                                  this.props.addEmptyContent(
                                                    sub_category1.subcategory_id
                                                  );
                                                }}
                                              >
                                                <span className="material-icons-outlined mr-1 icon-fs-18">
                                                  add
                                                </span>
                                                <span>Add Sections</span>
                                              </div>
                                            </div>
                                          )}
                                      </div>
                                    ))}
                                </div>
                              </div>
                            )
                        )}
                      </Collapsible>
                    </div>
                  </div>
                ) : null
              )}
            </div>

            <Modal
              isOpen={this.state.titleModal}
              toggle={this.closeTitleModal}
              className={
                "modal-lg custom-modal primary-modal modal-without-header"
              }
            >
              <ModalBody>
                <textarea
                  style={{ width: "100%" }}
                  rows={4}
                  value={this.state.modifiedTitleValue}
                  onChange={this.onTitleChangeHandler}
                ></textarea>
              </ModalBody>
              <ModalFooter>
                <Button
                  className={"btn-outline-primary modal-btn"}
                  onClick={this.closeTitleModal}
                >
                  Cancel
                </Button>
                <Button
                  className={"modal-right-btn modal-btn"}
                  onClick={this.saveTitleModal}
                >
                  Save
                </Button>{" "}
              </ModalFooter>
            </Modal>
            <Modal
              isOpen={this.state.addAuthorModal}
              toggle={this.closeAuthorModal}
              className={
                "modal-sm custom-modal primary-modal modal-without-header"
              }
            >
              <ModalBody>
                <div className={"row"}>
                  <form className={"col-lg-12 col-sm-12 form-wrapper"}>
                    <div className={"row"}>
                      <div className="col-lg-12 col-sm-12 position-relative">
                        <label for="companies">
                          Author <span className={"mandatory"}>* </span>
                        </label>
                        {tbriUsers && (
                          <div className="custom-combobox-list">
                            <CustomDropdownList
                              isDisabled={false}
                              data={tbriUsers}
                              handleChange={this.props.handleAuthorChange}
                              placeholder={"Select Author"}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </ModalBody>
              {tbriUsers && tbriUsers.length > 0 && (
                <ModalFooter>
                  <Button
                    className={"btn-outline-primary modal-btn"}
                    onClick={this.closeAuthorModal}
                  >
                    Cancel
                  </Button>
                  <Button
                    className={"modal-right-btn modal-btn"}
                    onClick={this.saveAddAuthorsModal}
                    disabled={!tbriUsers.find((u) => u.is_selected === true)}
                  >
                    Add
                  </Button>{" "}
                </ModalFooter>
              )}
            </Modal>
            <DailogNew
              isOpen={this.state.dailogModal}
              accept={this.dailogModalAccept}
              decline={this.dailogModalDecline}
              header={this.state.dailogModalHeader}
              content={this.state.dailogModalContent}
              config={this.state.dailogModalConfig}
              modalStyleType={this.state.dailogModalStyleType}
            />
          </>
        )}
      </aside>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    company_details: state.company_details,
    home: state.home,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    categories: (payload) => {
      dispatch(categories(payload));
    },
    companies: (payload) => {
      dispatch(companies(payload));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyDetailsContent);
