import React, { Component } from "react";
import { connect } from "react-redux";
import { formatRoute } from "react-router-named-routes";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import CustomDateTimePicker from "../Components/CustomDateTimePicker";
import CustomDropdownList from "../Components/CustomDropdownList";
import DailogNew from "../Components/DailogNew";
import Notification from "../Components/Notification";
import ScrollToTopBtn from "../Components/ScrollToTop";
import TemplateParser from "../Components/TemplateParser";
import TemplatePicker from "../Components/TemplatePicker";
import permissionCheck from "../lib/permissionCheck";
import constants from "../Utils/constants";
import DocumentTitle from "../Utils/DocumentTitle";
import Toast from "../Utils/Toast";
import Collapsible from "react-collapsible";
import CustomTitle from "../Components/CustomTitle";

class DetailsContent extends Component {
  state = {
    titleModal: false,
    uid: null,
    placeholder: null,
    originalTitleValue: null,
    modifiedTitleValue: null,
    dailogModal: false,
    dailogModalContent: "",
    dailogModalHeader: "",
    dailogModalConfig: {},
    dailogModalStyleType: "",
    addAuthorModal: false,
  };

  toggleTitleModal = (originalTitleValue, uid) => {
    this.setState({
      titleModal: !this.state.titleModal,
      originalTitleValue: originalTitleValue,
      modifiedTitleValue: originalTitleValue,
      uid: uid,
    });
  };
  componentDidMount() {
    this.tempToggleModal(true);
    setTimeout(() => {
      this.tempToggleModal(false);
    }, 200);
  }

  // method for to re-render the content
  tempToggleModal = (param) => {
    this.setState({
      titleModal: param,
    });
  };

  closeTitleModal = () => {
    this.setState({
      titleModal: false,
    });
  };

  saveTitleModal = () => {
    if (this.state.modifiedTitleValue.length > 300) {
      Toast(constants.TITLE_LIMIT_300, "error");
    } else {
      this.setState(
        {
          originalTitleValue: this.state.modifiedTitleValue,
        },
        () => {
          this.props.onSectionTitleChangeHandler(
            this.state.uid,
            this.state.originalTitleValue
          );
          this.closeTitleModal();
        }
      );
    }
  };

  onTitleChangeHandler = (e) => {
    this.setState({
      modifiedTitleValue: e.target.value,
    });
  };

  confirmDailog = (uid) => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">Delete</h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="remove-body">
          <h2 className="font-weight-semibold mb-0">
            {
              constants.MANAGE_CONTENT_TRANSACTION_DETAILS
                .DELETE_CONTENT_CONFIRM_MESSAGE
            }
          </h2>
        </div>
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: { type: "section", uid: uid },
      dailogModalStyleType: "danger-modal",
    });
  };

  confirmDailogUndo = (uid) => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">Undo</h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="inactive-body">
          <h2 className="font-weight-semibold mb-0">
            {
              constants.MANAGE_CONTENT_TRANSACTION_DETAILS
                .UNDO_DELETE_ACTION_CONFIRM_MESSAGE
            }
          </h2>
        </div>
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: { type: "section_recover", uid: uid },
      dailogModalStyleType: "inactive-modal",
    });
  };

  dailogModalAccept = (config) => {
    if (config.type === "section") {
      this.props.removeContent(config.uid);
      this.setState({
        dailogModal: false,
        dailogModalHeader: "",
        dailogModalContent: "",
        dailogModalConfig: {},
        dailogModalStyleType: "",
      });
    } else if (config.type === "section_recover") {
      this.props.undoRemoveContent(config.uid);
      this.setState({
        dailogModal: false,
        dailogModalHeader: "",
        dailogModalContent: "",
        dailogModalConfig: {},
        dailogModalStyleType: "",
      });
    } else if (config.type === "remove_author") {
      this.props.removeAuthor(config.uid);
      this.setState({
        dailogModal: false,
        dailogModalHeader: "",
        dailogModalContent: "",
        dailogModalConfig: {},
      });
      Toast(
        constants.MANAGE_CONTENT_TRANSACTION_DETAILS
          .DELETE_AUTHOR_SUCCESS_MESSAGE,
        "success"
      );
    }
  };

  dailogModalDecline = () => {
    this.setState({
      dailogModal: false,
      dailogModalContent: "",
      dailogModalConfig: {},
    });
  };

  breadcrumbs = () => {
    let groupName = "",
      listRoute = "",
      listName = "";
    let details = {};
    const componentName = this.props.componentName;
    switch (componentName) {
      case constants.MODULE_NAMES.SPECIAL_REPORT:
        details =
          this.props.special_report_details &&
          this.props.special_report_details.specialReportDetails
            ? this.props.special_report_details.specialReportDetails
            : {};
        groupName = constants.APPLICATION_ROUTE.SPECIAL_REPORT_GROUP.NAME;
        listName = constants.APPLICATION_ROUTE.SPECIAL_REPORT.LIST.NAME;
        listRoute = constants.APPLICATION_ROUTE.SPECIAL_REPORT.LIST.ROUTE;
        break;
      case constants.MODULE_NAMES.BENCHMARK:
        details =
          this.props.benchmark_details &&
          this.props.benchmark_details.benchmarkDetails
            ? this.props.benchmark_details.benchmarkDetails
            : {};
        groupName = constants.APPLICATION_ROUTE.BENCHMARK_GROUP.NAME;
        listName = constants.APPLICATION_ROUTE.BENCHMARK.LIST.NAME;
        listRoute = constants.APPLICATION_ROUTE.BENCHMARK.LIST.ROUTE;
        break;
      default:
        break;
    }
    let breadcrumbs = [
      {
        title: constants.APPLICATION_ROUTE.DASHBOARD.NAME,
        link: formatRoute(constants.APPLICATION_ROUTE.DASHBOARD.ROUTE, {}),
        is_active: false,
      },
      {
        title: groupName,
        link: null,
        is_active: false,
      },
      {
        title: listName,
        link: formatRoute(listRoute, {}),
        is_active: false,
      },
      {
        title: `${details.id ? details.name : ""}`,
        link: null,
        is_active: true,
      },
    ];
    DocumentTitle(`${details.id ? details.name : ""}`);
    return breadcrumbs;
  };

  toggleAddAuthorsModal = () => {
    this.setState({
      addAuthorModal: !this.state.addAuthorModal,
    });
  };

  closeAuthorModal = () => {
    this.setState({
      addAuthorModal: false,
    });
  };

  saveAddAuthorsModal = () => {
    this.setState(
      {
        addAuthorModal: false,
      },
      () => {
        this.props.addAuthor();
        Toast(
          constants.MANAGE_CONTENT_TRANSACTION_DETAILS
            .ADD_AUTHOR_SUCCESS_MESSAGE,
          "success"
        );
      }
    );
  };

  removeAuthorConfirmation = (author) => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">Remove</h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="remove-body">
          <h2 className="font-weight-semibold mb-0">
            {
              constants.MANAGE_CONTENT_TRANSACTION_DETAILS
                .DELETE_AUTHOR_CONFIRM_MESSAGE
            }
          </h2>
        </div>
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: { type: "remove_author", uid: author.author.id },
      dailogModalStyleType: "danger-modal",
    });
  };

  // This method is used to set the detailContent,categories,author,tbriUsers data based on the componentName you are passing
  // componentName for ex - MARKET_FORECAST,SPECIAL_REPORT,BENCHMARK etc..., these values get from Constants.moduleNames
  getDetailsContent = () => {
    const componentName = this.props.componentName;
    switch (componentName) {
      case constants.MODULE_NAMES.SPECIAL_REPORT:
        return {
          content:
            this.props.special_report_details &&
            this.props.special_report_details.specialReportContent
              ? this.props.special_report_details.specialReportContent
              : [],
          categories:
            this.props.special_report_details &&
            this.props.special_report_details.specialReportCategories
              ? this.props.special_report_details.specialReportCategories
              : [],
          templates:
            this.props.special_report_details &&
            this.props.special_report_details.specialReportTemplates
              ? this.props.special_report_details.specialReportTemplates
              : [],
          details:
            this.props.special_report_details &&
            this.props.special_report_details.specialReportDetails
              ? this.props.special_report_details.specialReportDetails
              : {},
          draftSlug:
            constants.MANAGE_CONTENT_PERMISSIONS.SPECIAL_REPORT_DRAFT.SLUG,
          submitSlug:
            constants.MANAGE_CONTENT_PERMISSIONS.SPECIAL_REPORT_SUBMIT.SLUG,
          draftButtonTitle:
            constants.MANAGE_CONTENT_PERMISSIONS.SPECIAL_REPORT_DRAFT
              .BUTTON_TITLE,
          submitButtonTitle:
            constants.MANAGE_CONTENT_PERMISSIONS.SPECIAL_REPORT_SUBMIT
              .BUTTON_TITLE,
        };

      case constants.MODULE_NAMES.BENCHMARK:
        return {
          content:
            this.props.benchmark_details &&
            this.props.benchmark_details.benchmarkContent
              ? this.props.benchmark_details.benchmarkContent
              : [],
          categories:
            this.props.benchmark_details &&
            this.props.benchmark_details.benchmarkCategories
              ? this.props.benchmark_details.benchmarkCategories
              : [],
          templates:
            this.props.benchmark_details &&
            this.props.benchmark_details.benchmarkTemplates
              ? this.props.benchmark_details.benchmarkTemplates
              : [],
          details:
            this.props.benchmark_details &&
            this.props.benchmark_details.benchmarkDetails
              ? this.props.benchmark_details.benchmarkDetails
              : {},
          draftSlug: constants.MANAGE_CONTENT_PERMISSIONS.BENCHMARK_DRAFT.SLUG,
          submitSlug:
            constants.MANAGE_CONTENT_PERMISSIONS.BENCHMARK_SUBMIT.SLUG,
          draftButtonTitle:
            constants.MANAGE_CONTENT_PERMISSIONS.BENCHMARK_DRAFT.BUTTON_TITLE,
          submitButtonTitle:
            constants.MANAGE_CONTENT_PERMISSIONS.BENCHMARK_SUBMIT.BUTTON_TITLE,
        };

      default:
        break;
    }
  };

  render() {
    let {
      content,
      categories,
      templates,
      details,
      draftSlug,
      submitSlug,
      draftButtonTitle,
      submitButtonTitle,
    } = this.getDetailsContent();
    let authors = details.authors ? details.authors : [];
    let tbriUsers =
      this.props.home && this.props.home.getTbriUsers
        ? this.props.home.getTbriUsers
        : [];
    let authorIds = authors.map((a) => a.id);
    tbriUsers = tbriUsers.filter((t) => !authorIds.includes(t.id));
    let hrefLink = "#";
    return (
      <aside className="dash_board_content inner-sidebar-right-content">
        <div className="col-xl-12">
          {this.props.apiError !== null && (
            <Notification color={"danger"} message={this.props.apiError} />
          )}
        </div>
        <ScrollToTopBtn />

        <div className="d-flex align-items-center">
          {this.props.isEditable && (
            <div className="ml-auto">
              <div className={"text-right"}>
                {categories.length > 0 && (
                  <button
                    className={
                      "btn btn-custom btn-danger icon-text-btn title-btn d-inline-flex align-items-center mb-3"
                    }
                    type="button"
                    onClick={this.props.resetContent}
                    disabled={!this.props.changesMade}
                  >
                    {" Reset"}
                    <span className="material-icons-outlined icon-fs-16 ml-1">
                      restart_alt
                    </span>
                  </button>
                )}
                {permissionCheck(
                  constants.MANAGE_CONTENT_PERMISSIONS.KEY,
                  constants.MANAGE_CONTENT_PERMISSIONS.ENTITY,
                  draftSlug
                ) &&
                  categories.length > 0 && (
                    <button
                      className={
                        "btn btn-custom btn-success icon-text-btn title-btn d-inline-flex align-items-center mb-3 mx-3"
                      }
                      type="button"
                      onClick={() => {
                        this.props.saveContent(draftSlug);
                      }}
                      disabled={!this.props.changesMade}
                    >
                      {" " + draftButtonTitle}
                      <span className="material-icons-outlined icon-fs-16 ml-1">
                        save
                      </span>
                    </button>
                  )}
                {permissionCheck(
                  constants.MANAGE_CONTENT_PERMISSIONS.KEY,
                  constants.MANAGE_CONTENT_PERMISSIONS.ENTITY,
                  submitSlug
                ) &&
                  categories.length > 0 && (
                    <button
                      className={
                        "btn btn-custom btn-primary icon-text-btn title-btn d-inline-flex align-items-center mb-3"
                      }
                      type="button"
                      onClick={() => {
                        this.props.saveContent(submitSlug);
                      }}
                      disabled={!this.props.changesMade}
                    >
                      {" " + submitButtonTitle}
                      <span className="material-icons-outlined icon-fs-16 ml-1">
                        done_all
                      </span>
                    </button>
                  )}
              </div>
            </div>
          )}
        </div>
        <div className="item_details_scroll">
          {categories.map(
            (category, index) =>
              (category.is_selected || category.type) && (
                <div className="custom-collapser custom-collapser2 company-details-collapser details-page-collapser">
                  <div className="category-wrapper">
                    <Collapsible
                      open={true}
                      trigger={
                        <div className="collapse-trigger">
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center">
                              <CustomTitle
                                title={category.name}
                                title_html={category.name_html}
                                tag="h3"
                                className="font-weight-bold mb-0 title category_name"
                              />
                            </div>
                            <div className="d-flex align-items-center">
                              <div className="collapse-icon-wrapper">
                                <span className="material-icons-round add icon">
                                  add
                                </span>
                                <span className="material-icons-round remove icon">
                                  remove
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      }
                    >
                      {category.subcategories.map(
                        (sub_category, sub_index) =>
                          content &&
                          content.length > 0 &&
                          (sub_category.contents || sub_category.is_selected) &&
                          content.filter(
                            (segment) =>
                              segment.subcategory_id === sub_category.uid
                          ).length > 0 && (
                            <div
                              className="sub-category-section"
                              key={sub_index}
                            >
                              <div className="tbr_position inner-content-section">
                                <div className="">
                                  <div className="sub_category_heading  d-flex align-items-center">
                                    <CustomTitle
                                      title={sub_category.name}
                                      title_html={sub_category.name_html}
                                      tag="h3"
                                      className="heading mb-0 sub_category_heading_title ellipsis"
                                    />
                                    {content.filter(
                                      (segment) =>
                                        segment.subcategory_id ===
                                          sub_category.uid &&
                                        !segment.is_deleted
                                    )[0] && (
                                      <>
                                        {content.filter(
                                          (segment) =>
                                            segment.subcategory_id ===
                                              sub_category.uid &&
                                            !segment.is_deleted
                                        )[0].published_date && (
                                          <>
                                            <div className="d-flex align-items-center">
                                              <h5 className="font-weight-semibold text-black2 mb-0">
                                                Published
                                              </h5>
                                              <div className="custom-date-time-picker ml-10px">
                                                <CustomDateTimePicker
                                                  isDisabled={
                                                    !this.props.isEditable
                                                  }
                                                  id={
                                                    content.filter(
                                                      (segment) =>
                                                        segment.subcategory_id ===
                                                          sub_category.uid &&
                                                        !segment.is_deleted
                                                    )[0].uid
                                                  }
                                                  date={
                                                    content.filter(
                                                      (segment) =>
                                                        segment.subcategory_id ===
                                                          sub_category.uid &&
                                                        !segment.is_deleted
                                                    )[0].published_date
                                                  }
                                                  onChangeHandler={
                                                    this.props
                                                      .dateOnChangeHandler
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </>
                                        )}
                                      </>
                                    )}
                                  </div>
                                </div>
                                {content
                                  .filter(
                                    (segment) =>
                                      segment.subcategory_id ===
                                      sub_category.uid
                                  )
                                  .map((sub_category1, sub_index1) => (
                                    <div
                                      key={sub_category1.uid}
                                      className="content-section"
                                    >
                                      {
                                        <div
                                          className="title d-flex align-items-center justify-content-between"
                                          key={sub_category1.uid}
                                        >
                                          <div
                                            className={`${
                                              sub_category1.is_deleted
                                                ? "content_deleted"
                                                : ""
                                            } text-primary mb-0 d-inline-flex align-items-center`}
                                          >
                                            {!sub_category1.is_deleted &&
                                              this.props.isEditable && (
                                                <>
                                                  <span
                                                    className="material-icons manage_content_action icon-fs-16 mr-1"
                                                    onClick={() => {
                                                      this.toggleTitleModal(
                                                        sub_category1.subcategory_title,
                                                        sub_category1.uid
                                                      );
                                                    }}
                                                  >
                                                    edit
                                                  </span>
                                                  &nbsp;
                                                </>
                                              )}
                                            <CustomTitle
                                              title={
                                                sub_category1.subcategory_title
                                                  ? sub_category1.subcategory_title
                                                  : constants.COMPANY_DETAILS
                                                      .TITLE_PLACE_HOLDER
                                              }
                                              title_html={
                                                sub_category1.subcategory_title_html
                                              }
                                              tag="h5"
                                              className={`${
                                                sub_category1.is_deleted
                                                  ? "content_deleted"
                                                  : ""
                                              } text-primary mb-0 d-inline-flex align-items-center`}
                                            />
                                          </div>
                                          {this.props.isEditable && (
                                            <div style={{ textAlign: "right" }}>
                                              {!sub_category1.is_deleted && (
                                                <div
                                                  className="del-undo-icon-wrapper d-inline-flex"
                                                  onClick={() => {
                                                    this.confirmDailog(
                                                      sub_category1.uid
                                                    );
                                                  }}
                                                >
                                                  <span className="material-icons-outlined icon icon-fs-17">
                                                    delete_outline
                                                  </span>
                                                </div>
                                              )}
                                              {sub_category1.is_deleted && (
                                                <div
                                                  className="del-undo-icon-wrapper d-inline-flex"
                                                  onClick={() => {
                                                    this.confirmDailogUndo(
                                                      sub_category1.uid
                                                    );
                                                  }}
                                                >
                                                  <span className="material-icons-outlined icon icon-fs-17">
                                                    undo
                                                  </span>
                                                </div>
                                              )}
                                            </div>
                                          )}
                                        </div>
                                      }
                                      {!sub_category1.template_id && (
                                        <div className="my-3">
                                          <TemplatePicker
                                            key={`template-picker-${sub_category1.uid}`}
                                            uid={sub_category1.uid}
                                            templates={templates}
                                            onTemplateChangeHandler={
                                              this.props.onTemplateChangeHandler
                                            }
                                          />
                                        </div>
                                      )}
                                      {sub_category1.template_id && (
                                        <div className="content_section template-content-section">
                                          <TemplateParser
                                            emptyContentMessage={
                                              constants.COMPANY_DETAILS
                                                .CONTENT_PLACE_HOLDER
                                            }
                                            isEditable={this.props.isEditable}
                                            isDeleted={sub_category1.is_deleted}
                                            key={`temp-${sub_category1.uid}`}
                                            template={
                                              sub_category1.template.content
                                            }
                                            content={sub_category1.data}
                                            uid={sub_category1.uid}
                                            onContentChangeHandler={
                                              this.props.onContentChangeHandler
                                            }
                                            tempToggleModal={
                                              this.tempToggleModal
                                            }
                                          />
                                        </div>
                                      )}
                                      {sub_index1 + 1 ===
                                        content.filter(
                                          (segment) =>
                                            segment.subcategory_id ===
                                            sub_category.uid
                                        ).length &&
                                        this.props.isEditable && (
                                          <div>
                                            <div
                                              className="add-section-btn d-inline-flex align-items-center cursor-pointer"
                                              onClick={() => {
                                                this.props.addEmptyContent(
                                                  sub_category1.subcategory_id
                                                );
                                              }}
                                            >
                                              <span className="material-icons-outlined mr-1 icon-fs-18">
                                                add
                                              </span>
                                              <span>Add Sections</span>
                                            </div>
                                          </div>
                                        )}
                                    </div>
                                  ))}
                              </div>
                            </div>
                          )
                      )}
                    </Collapsible>
                  </div>
                </div>
              )
          )}
        </div>

        <Modal
          isOpen={this.state.titleModal}
          toggle={this.closeTitleModal}
          className={"modal-lg custom-modal primary-modal modal-without-header"}
        >
          <ModalBody>
            <textarea
              style={{ width: "100%" }}
              rows={4}
              value={this.state.modifiedTitleValue ?? ""}
              onChange={this.onTitleChangeHandler}
            ></textarea>
          </ModalBody>
          <ModalFooter>
            <Button
              className={"btn-outline-primary modal-btn"}
              onClick={this.closeTitleModal}
            >
              Cancel
            </Button>{" "}
            <Button
              className={"modal-right-btn modal-btn"}
              onClick={this.saveTitleModal}
            >
              Save
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.addAuthorModal}
          toggle={this.closeAuthorModal}
          className={"modal-lg custom-modal primary-modal modal-without-header"}
        >
          <ModalBody>
            <div className={"row"}>
              <form className={"col-lg-12 col-sm-12 form-wrapper"}>
                <div className={"row"}>
                  <div className="col-lg-12 col-sm-12 form-group position-relative">
                    <label for="companies">
                      Author <span className={"mandatory"}>* </span>
                    </label>
                    {tbriUsers && (
                      <div className="custom-combobox-list">
                        <CustomDropdownList
                          isDisabled={false}
                          data={tbriUsers}
                          handleChange={this.props.handleAuthorChange}
                          placeholder={"Select Author"}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </ModalBody>
          {tbriUsers && tbriUsers.length > 0 && (
            <ModalFooter>
              <Button
                className={"btn-outline-primary modal-btn"}
                onClick={this.closeAuthorModal}
              >
                Cancel
              </Button>{" "}
              <Button
                className={"modal-right-btn modal-btn"}
                onClick={this.saveAddAuthorsModal}
                disabled={!tbriUsers.find((u) => u.is_selected === true)}
              >
                Add
              </Button>
            </ModalFooter>
          )}
        </Modal>
        <DailogNew
          isOpen={this.state.dailogModal}
          accept={this.dailogModalAccept}
          decline={this.dailogModalDecline}
          header={this.state.dailogModalHeader}
          content={this.state.dailogModalContent}
          config={this.state.dailogModalConfig}
          modalStyleType={this.state.dailogModalStyleType}
        />
      </aside>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    special_report_details: state.special_report_details,
  };
};

export default connect(mapStateToProps, null)(DetailsContent);
