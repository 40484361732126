import React, { useState, useEffect, useRef } from "react";
import constants from "../Utils/constants";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import Collapsible from "react-collapsible";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import TimePicker from "rc-time-picker";
import { activeMenuTab } from "../Store/Actions/User";
import { connect } from "react-redux";
import { UserServices } from "../Services/User";
import { CalendarServices } from "../Services/Calendar";
import decodeJWT from "../lib/decodeJWT";
import Toast from "../Utils/Toast";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "rc-time-picker/assets/index.css";
import "../Styles/calendar.scss";
import CalendarHeader from "../Components/CalendarHeader";
import moment from "moment";
import Select from "react-select";
import JoditEditor from "jodit-react";
const numWhiteSpaceRegex = /^(\s+|\d+)$/;

const config = {
  readonly: false, // All other default Jodit settings
  showCharsCounter: false, // Disable character count
  showWordsCounter: false, // Disable word count
  showPoweredByJodit: false, // Remove "Powered by Jodit" text
  height: 200,
};

const webinarTypesArray = [
  {
    id: 1,
    name: constants.CALENDAR.WEBINAR_TYPE.DRAFT,
    slug: constants.CALENDAR.SLUG.DRAFT,
    is_selected: true,
    color: "gray",
  },
  {
    id: 2,
    name: constants.CALENDAR.WEBINAR_TYPE.PUBLISHED,
    slug: constants.CALENDAR.SLUG.PUBLISHED,
    is_selected: true,
    color: "skateblue",
  },
];

const viewsForCalendar = {
  timeGridWeek: {
    type: "timeGrid",
    allDaySlot: false,
    expandRows: true,
    eventMaxStack: 1,
  },
  timeGridDay: {
    type: "dayGrid",
    allDaySlot: false,
    expandRows: true,
    eventMaxStack: 1,
  },
};

const WebinarCalendar = (props) => {
  const [webinarTypes, setWebinarTypes] = useState(webinarTypesArray);
  const [placeholderStartTime, setPlaceholderStartTime] = useState("");
  const [placeholderEndTime, setPlaceholderEndTime] = useState("");
  const [collapseIsOpen, setCollapseIsOpen] = useState(true);
  const [createEventModalIsOpen, setCreateEventModalIsOpen] = useState(false);
  const [showEventModalIsOpen, setShowEventModalIsOpen] = useState(false);
  const [eventTitle, setEventTitle] = useState("");
  const [eventDescription, setEventDescription] = useState("");
  const [webinarLink, setWebinarLink] = useState("");
  const [eventDate, setEventDate] = useState(new Date());
  const [eventStartTime, setEventStartTime] = useState("");
  const [eventEndTime, setEventEndTime] = useState("");
  const [hostName, setHostName] = useState("");
  const [hostDescription, setHostDescription] = useState("");
  const [host_description, set_host_description] = useState("");
  const [hostUserList, setHostUserList] = useState([]);
  const [allEvents, setAllEvents] = useState([]);
  const [displayEvents, setDisplayEvents] = useState([]);
  const [selectedEventId, setSelectedEventId] = useState("");
  const [buttonType, setButtonType] = useState("publish");
  const [eventCreatorId, setEventCreatorId] = useState("");
  const [loginUserId, setLoginUserId] = useState("");
  const [isLinkValid, setIsLinkValid] = useState(true);
  const [showFullDate, setShowFullDate] = useState("");
  const [searchText, setSearchText] = useState("");
  const [activeView, setActiveView] = React.useState("dayGridMonth");
  const [webinarDurationTime, setWebinarDurationTime] = useState("");
  const [calendarHeaderDate, setCalendarHeaderDate] = useState("");
  const [selectedHost, setSelectedHost] = useState({});
  const [formErrors, setFormErrors] = useState({
    titleError: false,
    descriptionError: false,
    hostError: false,
    linkError: false,
    startTimeError: false,
    endTimeError: false,
  });
  const calendarRef = useRef(null);

  useEffect(() => {
    const { current: calendarDom } = calendarRef;
    const API = calendarDom ? calendarDom.getApi() : null;
    API && API.changeView(activeView);
    const prevButton = document.getElementById("calendar-prev-btn");
    const nextButton = document.getElementById("calendar-next-btn");
    const handlePrev = () => {
      API.prev();
      setCalendarHeaderDate(API.view.title);
    };
    const handleNext = () => {
      API.next();
      setCalendarHeaderDate(API.view.title);
    };
    prevButton.addEventListener("click", handlePrev);
    nextButton.addEventListener("click", handleNext);
    setCalendarHeaderDate(API.view.title);
    return () => {
      prevButton.removeEventListener("click", handlePrev);
      nextButton.removeEventListener("click", handleNext);
    };
  }, [activeView]);

  useEffect(() => {
    let tokenDetails = decodeJWT(localStorage.getItem("token"));
    setLoginUserId(tokenDetails?.user?.id);
    getAllCalendarEvents();
    getHostUserList();
    props.activeMenuTab(
      constants.APPLICATION_ROUTE.CALENDAR_WEBINAR.LIST.ROUTE
    );
  }, []);

  const getHostUserList = () => {
    let url = "users?role_type=tbr_admin";
    UserServices.getUsers(url)
      .then((res) => {
        const userList = res.data.data;
        const updatedUserList = [];
        userList.forEach((user) => {
          let obj = {};
          obj["id"] = user.id;
          obj["label"] = `${user.first_name} ${user.last_name}`;
          obj["value"] = user.id;
          obj["description"] = user.description;
          updatedUserList.push(obj);
        });
        setHostUserList(updatedUserList);
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  const getAllCalendarEvents = () => {
    CalendarServices.getCalendarEvent()
      .then((res) => {
        const data = res.data;
        data.map((event) => {
          event["title"] = event.name;
          event["start"] = event.start_date_time.split(".")[0];
          event["end"] = event.end_date_time.split(".")[0];
          if (event.status === "publish") {
            event["className"] = ["event", "publishedEvent"];
          } else {
            event["className"] = ["event", "draftEvent"];
          }
        });
        setAllEvents(data);
        setDisplayEvents(data);
      })
      .catch((error) => {
        setAllEvents([]);
        setDisplayEvents([]);
      });
  };

  const createTheCalendarEvent = (eventType) => {
    if (!isFormValid() && isLinkValid) {
      let body = {
        name: eventTitle,
        description: eventDescription,
        start_date_time: convertTime12To24Format(eventStartTime),
        end_date_time: convertTime12To24Format(eventEndTime),
        event_url: webinarLink,
        type: "webinar",
        status: eventType,
        host_id: selectedHost.id,
        host_description: host_description,
      };
      CalendarServices.createCalendarEvent(body)
        .then((res) => {
          Toast(
            res && res.data && res.data.message
              ? res.data.message
              : "New event added successfully",
            "success"
          );
          getAllCalendarEvents();
          setCreateEventModalIsOpen(false);
          getHostUserList();
        })
        .catch((error) => {
          Toast(
            error && error.data && error.data.error
              ? error.data.error
              : constants.ERROR.SOMETHING_WENT_WRONG,
            "error"
          );
        });
    }
  };

  const isFormValid = () => {
    let formError = false;
    let titleError = false;
    let descriptionError = false;
    let hostError = false;
    let linkError = false;
    let startTimeError = false;
    let endTimeError = false;

    if (eventTitle.length === 0) {
      titleError = true;
      formError = true;
    }
    if (eventDescription.length === 0) {
      descriptionError = true;
      formError = true;
    }
    if (Object.keys(selectedHost).length === 0) {
      hostError = true;
      formError = true;
    }
    if (webinarLink.length === 0) {
      linkError = true;
      formError = true;
    }
    if (eventStartTime === "") {
      startTimeError = true;
      formError = true;
    }
    if (eventEndTime === "") {
      endTimeError = true;
      formError = true;
    }
    const formErrorObject = {
      titleError,
      descriptionError,
      hostError,
      linkError,
      startTimeError,
      endTimeError,
    };
    setFormErrors(formErrorObject);
    return formError;
  };

  const updateTheEventDetails = (eventType) => {
    if (!isFormValid() && isLinkValid) {
      let body = {
        name: eventTitle,
        description: eventDescription,
        start_date_time: convertTime12To24Format(eventStartTime),
        end_date_time: convertTime12To24Format(eventEndTime),
        event_url: webinarLink,
        type: "webinar",
        status: eventType,
        host_id: selectedHost.id,
        host_description: host_description,
      };
      CalendarServices.updateCalendarEvent(selectedEventId, body)
        .then((res) => {
          Toast(
            res && res.data && res.data.message
              ? res.data.message
              : "Event Updated",
            "success"
          );
          setCreateEventModalIsOpen(false);
          getAllCalendarEvents();
          getHostUserList();
        })
        .catch((error) => {
          Toast(
            error && error.data && error.data.error
              ? error.data.error
              : constants.ERROR.SOMETHING_WENT_WRONG,
            "error"
          );
        });
    }
  };

  const deleteTheCalendarEvent = () => {
    CalendarServices.deleteCalendarEvent(selectedEventId)
      .then((res) => {
        Toast(
          res && res.data && res.data.message
            ? res.data.message
            : "Event Deleted",
          "success"
        );
        getAllCalendarEvents();
        setShowEventModalIsOpen(false);
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  const openCreateEventModal = (data) => {
    setEventTitle("");
    setEventDescription("");
    setEventStartTime("");
    setEventEndTime("");
    setWebinarLink("");
    setHostName("");
    setHostDescription("");
    set_host_description("");
    setSelectedHost("");
    setIsLinkValid(true);
    setPlaceholderStartTime("");
    setPlaceholderEndTime("");
    let eventDate = new Date();
    if (data.date) {
      eventDate = data.date;
    }
    setEventDate(eventDate);
    setCreateEventModalIsOpen(true);
  };

  const showEventDetails = (e) => {
    const eventDetail = e.event?._def.extendedProps;
    const host_name = `${eventDetail.host?.first_name} ${eventDetail.host?.last_name}`;
    const host_description = eventDetail.host?.description;
    const date = eventDetail?.start_date_time.split("T")[0];
    const startTime = eventDetail?.start_date_time.split("T")[1];
    const endTime = eventDetail?.end_date_time.split("T")[1];
    const convertStartTime = convertTime24To12HourFormat(startTime);
    const convertEndTime = convertTime24To12HourFormat(endTime);

    const durationTime = calculateDurationTime(
      eventDetail?.start_date_time,
      eventDetail?.end_date_time
    );
    const hostDetail = hostUserList.find(
      (host) => host.id == eventDetail.host_id
    );
    let timeZoneOffset = new Date().getTimezoneOffset();
    let fullDateObject = new Date(eventDetail?.start_date_time);
    fullDateObject.setMinutes(
      fullDateObject.getMinutes() + timeZoneOffset + 60
    );
    let fullDate = fullDateObject.toLocaleDateString("en-us", {
      weekday: "long",
      month: "long",
      day: "numeric",
      year: "numeric",
      timeZone: "UTC",
    });
    setSelectedHost(hostDetail);
    setShowFullDate(fullDate);
    setWebinarDurationTime(durationTime);
    setHostName(host_name);
    setHostDescription(host_description);
    set_host_description(host_description);
    setEventCreatorId(eventDetail.created_by);
    setEventTitle(eventDetail.name);
    setEventDescription(eventDetail.description);
    setWebinarLink(eventDetail.event_url);
    setShowEventModalIsOpen(true);
    setEventDate(moment(date)?._d);
    setEventStartTime(convertStartTime);
    setEventEndTime(convertEndTime);
    setPlaceholderStartTime(convertStartTime);
    setPlaceholderEndTime(convertEndTime);
    setButtonType(eventDetail.status);
    setSelectedEventId(e.event._def.publicId);
  };

  const calculateDurationTime = (startTime, endTime) => {
    const difference = new Date(endTime) - new Date(startTime);
    const differenceInMinutes = difference / 1000 / 60;
    let hours = Math.floor(differenceInMinutes / 60);
    if (hours < 0) {
      hours = 24 + hours;
    }
    let minutes = Math.floor(differenceInMinutes % 60);
    if (minutes < 0) {
      minutes = 60 + minutes;
    }
    let hoursAndMinutes;
    if (hours === 0) {
      hoursAndMinutes =
        "0" + hours + ":" + (minutes < 10 ? "0" : "") + minutes + " minutes";
    } else {
      hoursAndMinutes =
        "0" + hours + ":" + (minutes < 10 ? "0" : "") + minutes + " hours";
    }
    return hoursAndMinutes;
  };

  const editEventDetail = () => {
    setButtonType("update");
    setShowEventModalIsOpen(false);
    setCreateEventModalIsOpen(true);
  };

  const convertTime24To12HourFormat = (timeString) => {
    const [hourString, minute] = timeString.split(":");
    const hour = +hourString % 24;
    return (hour % 12 || 12) + ":" + minute + (hour < 12 ? " AM" : " PM");
  };

  const convertTime12To24Format = (timeStr) => {
    const convertDate = moment(eventDate).format("YYYY-MM-DD");
    const [time, modifier] = timeStr.split(" ");
    let [hours, minutes] = time.split(":");
    if (hours === "12") {
      hours = "00";
    }
    if (modifier === "PM") {
      hours = parseInt(hours, 10) + 12;
    }
    return `${convertDate} ${hours}:${minutes}:00 +00:00`;
  };

  const handleHostDescriptionChange = (data) => {
    set_host_description(data);
  };

  const closeModal = () => {
    setButtonType("publish");
    setCreateEventModalIsOpen(false);
  };

  const checkLinkIsValid = (e) => {
    const regex = constants.REG_EX.WEBINAR_REG_EX;
    if (regex.test(e.target.value)) {
      setIsLinkValid(true);
    } else {
      setIsLinkValid(false);
    }

    if (e.target.value?.length === 0) {
      setIsLinkValid(true);
    }
    setFormErrors({ ...formErrors, linkError: false });
    setWebinarLink(e.target.value);
  };

  // handling the filtering events
  const handleCheckbox = (slug) => {
    const array = [];
    webinarTypes.forEach((checkbox) => {
      if (checkbox.slug === slug) {
        checkbox.is_selected = !checkbox.is_selected;
      }
      array.push(checkbox);
    });
    if (array[0].is_selected && array[1].is_selected) {
      setDisplayEvents(allEvents);
    } else if (array[0].is_selected) {
      const filteredEvents = allEvents.filter(
        (event) => event.status === "draft"
      );
      setDisplayEvents(filteredEvents);
    } else if (array[1].is_selected) {
      const filteredEvents = allEvents.filter(
        (event) => event.status === "publish"
      );
      setDisplayEvents(filteredEvents);
    } else {
      setDisplayEvents([]);
    }
    setWebinarTypes(array);
  };

  const searchTextResult = (e) => {
    let searchInput = e.target.value;
    setSearchText(searchInput);
    const searchResult = allEvents.filter((event) =>
      event.name.toLowerCase().includes(searchInput.toLowerCase())
    );
    setDisplayEvents(searchResult);
    if (searchInput.length > 0) {
      setActiveView("listYear");
    } else {
      setActiveView("dayGridMonth");
    }
  };

  const changeCalendarView = (viewType) => {
    setActiveView(viewType);
  };

  const handleTitleChange = (e) => {
    if (!numWhiteSpaceRegex.test(e.target.value)) {
      setEventTitle(e.target.value);
      setFormErrors({ ...formErrors, titleError: false });
    }
  };

  const handleDescriptionChange = (e) => {
    setEventDescription(e);
    setFormErrors({ ...formErrors, descriptionError: false });
  };
  const handleStartTime = (e) => {
    setEventStartTime(e.format("LT"));
    setFormErrors({ ...formErrors, startTimeError: false });
  };
  const handleEndTime = (e) => {
    setEventEndTime(e.format("LT"));
    setFormErrors({ ...formErrors, endTimeError: false });
  };

  const handleHostChange = (e) => {
    setSelectedHost(e);
    set_host_description(e.description || "");
    setFormErrors({ ...formErrors, hostError: false });
  };

  const customStyles = {
    control: (styles) => ({
      ...styles,
      width: "100%",
      minHeight: "30px",
      height: "auto",
    }),
    option: (provided) => ({
      ...provided,
      fontSize: "13px",
    }),
  };
  const customErrorStyles = {
    control: (styles) => ({
      ...styles,
      width: "100%",
      minHeight: "30px",
      height: "auto",
      border: "1px solid red",
    }),
    option: (provided) => ({
      ...provided,
      fontSize: "13px",
    }),
  };
  return (
    <aside className="main_content">
      <div className="m-3 mb-0 bg-white br_8 main_content_height d-flex">
        <div className="webinar-left">
          <h2>Webinar Calendar</h2>
          <Collapsible
            open={collapseIsOpen}
            trigger={
              <div
                onClick={() => setCollapseIsOpen(!collapseIsOpen)}
                className="webinar-collapse"
              >
                <h3 className="">Event Status</h3>
                <span
                  style={{ color: "#555555" }}
                  className="material-icons-outlined material-icon mr-2 cursor-pointer"
                >
                  {collapseIsOpen ? "expand_less" : "expand_more"}
                </span>
              </div>
            }
          >
            <div className="mt-3">
              {webinarTypes.map((webType) => (
                <div className="d-flex">
                  <div className="sb-checkbox">
                    <input
                      className="mt-1 sb-checkbox__input"
                      type="checkbox"
                      id={webType.id}
                      checked={webType.is_selected}
                      onChange={() => handleCheckbox(webType.slug)}
                    />
                    <label
                      className={`sb-checkbox__label sb-checkbox__label--${webType.color}`}
                      for={webType.id}
                    ></label>
                  </div>
                  <p className="webinar-checkbox-label">{webType.name}</p>
                </div>
              ))}
            </div>
          </Collapsible>
        </div>

        <div className="webinar-right">
          <CalendarHeader
            calendarHeaderDate={calendarHeaderDate}
            activeView={activeView}
            searchText={searchText}
            searchTextResult={searchTextResult}
            openCreateEventModal={openCreateEventModal}
            changeCalendarView={changeCalendarView}
          />
          <FullCalendar
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
              listPlugin,
            ]}
            dateClick={(e) => {
              openCreateEventModal(e);
            }}
            ref={calendarRef}
            initialView="dayGridMonth"
            editable={true}
            dayMaxEvents={4}
            select={openCreateEventModal}
            eventClick={showEventDetails}
            displayEventEnd={true}
            headerToolbar={false}
            height={"78vh"}
            eventTimeFormat={{
              hour: "numeric",
              minute: "2-digit",
            }}
            events={displayEvents}
            eventDisplay="block"
            views={viewsForCalendar}
          />
        </div>
      </div>
      <div>
        <Modal
          isOpen={createEventModalIsOpen}
          toggle={closeModal}
          style={{ width: "480px" }}
          className={"modal-lg modal-w-header custom-modal primary-modal"}
        >
          <ModalHeader>
            <div className="create-event-modal-header">
            <h2 className="">
                {buttonType === "update"
                  ? "Edit Webinar Event"
                  : "Add Webinar Event"}
              </h2>              <span
                onClick={closeModal}
                style={{ color: "#555555", float: "right", cursor: "Pointer" }}
                className="material-icons"
              >
                close
              </span>
            </div>
          </ModalHeader>
          <ModalBody>
            <div className="web-create-event-title">
              <p>
                Title <span className={"mandatory"}>* </span>
              </p>
              <input
                className={
                  formErrors.titleError ? "title-input-error" : "title-input"
                }
                type="text"
                value={eventTitle}
                placeholder="Enter title"
                onChange={(e) => handleTitleChange(e)}
              />
              {formErrors.titleError && (
                <div className="red-error-msg">
                  {constants.FORM.MANDATORY_FIELD}
                </div>
              )}
            </div>
            <div className="web-create-event-title mt-3">
              <p>
                Description <span className={"mandatory"}>* </span>
              </p>
              <JoditEditor
                onChange={handleDescriptionChange}
                config={config}
                className={
                  formErrors.descriptionError ? "description-input-error" : null
                }
                value={eventDescription}
              />

              {formErrors.descriptionError && (
                <div className="red-error-msg">
                  {constants.FORM.MANDATORY_FIELD}
                </div>
              )}
            </div>
            <div className="web-create-event-title mt-3">
              <p>
                Host<span className={"mandatory"}>* </span>
              </p>
              <div className="react-select-div">
                <Select
                  options={hostUserList}
                  placeholder="Select an option"
                  styles={
                    formErrors.hostError ? customErrorStyles : customStyles
                  }
                  value={selectedHost}
                  onChange={(opt) => handleHostChange(opt)}
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                />
                {formErrors.hostError && (
                  <div className="red-error-msg">
                    {constants.FORM.MANDATORY_FIELD}
                  </div>
                )}
              </div>
            </div>

            {selectedHost && selectedHost.id && (
              <div className="web-create-event-title mt-3">
                <p>Host Description</p>
                <textarea
                  className="description-input"
                  type="text"
                  value={host_description}
                  placeholder="Write some description"
                  onChange={(e) => handleHostDescriptionChange(e.target.value)}
                ></textarea>
              </div>
            )}
            <div className="web-create-event-title mt-3">
              <p>
                Webinar Link
                <span className={"mandatory"}>* </span>
              </p>
              <textarea
                className={
                  formErrors.linkError
                    ? "webinar-link-input-error"
                    : "webinar-link-input"
                }
                type="text"
                value={webinarLink}
                placeholder="Enter Webinar link"
                onChange={(e) => checkLinkIsValid(e)}
              ></textarea>
              {!isLinkValid && (
                <div className="red-error-msg">
                  {constants.FORM.INVALID_LINK}
                </div>
              )}
              {formErrors.linkError && (
                <div className="red-error-msg">
                  {constants.FORM.MANDATORY_FIELD}
                </div>
              )}
            </div>
            <div className="web-create-event-title mt-3">
              <p>
                Date
                <span className={"mandatory"}>* </span>
              </p>
              <div className="date-input">
                <DatePicker
                  className="date-picker"
                  dateFormat="MM/dd/yyyy"
                  selected={eventDate}
                  onChange={(d) => setEventDate(d)}
                  todayButton={"Today"}
                />
              </div>
            </div>

            <div className="start-end-time-div mt-3">
              <div className="start-time">
                <p>
                  Start Time
                  <span className={"mandatory"}>* </span>
                </p>
                <TimePicker
                  className={
                    formErrors.startTimeError
                      ? "time-picker-error"
                      : "time-picker"
                  }
                  placeholder={
                    buttonType === "publish"
                      ? "Select Time"
                      : placeholderStartTime
                  }
                  use12Hours
                  showSecond={false}
                  minuteStep={15}
                  focusOnOpen={true}
                  format="hh:mm A"
                  onChange={(e) => handleStartTime(e)}
                />
                {formErrors.startTimeError && (
                  <div className="red-error-msg">
                    {constants.FORM.MANDATORY_FIELD}
                  </div>
                )}
              </div>
              <div className="end-time">
                <p>
                  End Time
                  <span className={"mandatory"}>* </span>
                </p>
                <TimePicker
                  className={
                    formErrors.endTimeError
                      ? "time-picker-error"
                      : "time-picker"
                  }
                  placeholder={
                    buttonType === "publish"
                      ? "Select Time"
                      : placeholderEndTime
                  }
                  use12Hours
                  showSecond={false}
                  minuteStep={15}
                  focusOnOpen={true}
                  format="hh:mm A"
                  onChange={(e) => handleEndTime(e)}
                />
                {formErrors.endTimeError && (
                  <div className="red-error-msg">
                    {constants.FORM.MANDATORY_FIELD}
                  </div>
                )}
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="web-create-event-buttons mt-3">
              <button className="btn-cancel" onClick={closeModal}>
                Cancel
              </button>
              <button
                className="btn-save-draft ml-2"
                onClick={
                  buttonType === "publish"
                    ? () => createTheCalendarEvent("draft")
                    : () => updateTheEventDetails("draft")
                }
              >
                Save as draft
              </button>
              <button
                className="btn-publish ml-2"
                onClick={
                  buttonType === "publish"
                    ? () => createTheCalendarEvent("publish")
                    : () => updateTheEventDetails("publish")
                }
              >
                Publish
              </button>
            </div>
          </ModalFooter>
        </Modal>
      </div>
      <div>
        <Modal
          isOpen={showEventModalIsOpen}
          toggle={() => setShowEventModalIsOpen(false)}
          style={{ width: "400px" }}
          className={"modal-lg modal-w-header custom-modal primary-modal"}
        >
          <ModalHeader>
            <div className="show-event-modal-header">
              <h2 className="">Webinar Event Details</h2>
              <span
                onClick={() => setShowEventModalIsOpen(false)}
                style={{ color: "#555555", float: "right", cursor: "Pointer" }}
                className="material-icons"
              >
                close
              </span>
            </div>
          </ModalHeader>
          <ModalBody>
            <div className="d-flex">
              <span className="material-icons-outlined icon-fs-18">
                domain_verification
              </span>
              <p className="show-event-modal-text">{showFullDate}</p>
            </div>
            <div style={{ marginTop: "-8px" }} className="d-flex">
              <span className="material-icons-outlined icon-fs-18">
                alarm_on
              </span>
              <p className="show-event-modal-text">{`${placeholderStartTime} Eastern Time`}</p>
            </div>
            <div style={{ marginTop: "-8px" }} className="d-flex">
              <span className="material-icons-outlined icon-fs-18">
                timelapse
              </span>
              <p className="show-event-modal-text">{webinarDurationTime}</p>
            </div>
            <div className="show-event-modal-head-para">
              <h3 className="overflow-break-word">{eventTitle}</h3>
              <div dangerouslySetInnerHTML={{ __html: eventDescription }} />
            </div>
            <div className="show-event-modal-head-para">
              <div className="d-flex">
                <span className="material-icons-outlined icon-fs-18">
                  person
                </span>
                <h3 className="hosted-text">
                  Hosted By: <span>{hostName}</span>
                </h3>
              </div>
              {hostDescription && (
                <p className="ml-1 overflow-break-word">{hostDescription}</p>
              )}
            </div>
            <div>
              <h3 className="join-link-head">Join Link</h3>
              <a
                className="webinar-link-text"
                href={webinarLink}
                target="_blank"
              >
                {webinarLink}
              </a>
            </div>
          </ModalBody>
          {/* {eventCreatorId === loginUserId && ( */}
          <ModalFooter>
            <div className="show-event-modal-btn">
              <button className="btn-edit" onClick={editEventDetail}>
                Edit
              </button>
              <button
                className="btn-delete ml-2"
                onClick={deleteTheCalendarEvent}
              >
                Delete
              </button>
            </div>
          </ModalFooter>
          {/* )} */}
        </Modal>
      </div>
    </aside>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    activeMenuTab: (tab) => {
      dispatch(activeMenuTab(tab));
    },
  };
}

const mapStateToProps = (state) => {
  return {
    home: state.home,
    client: state.client,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WebinarCalendar);
